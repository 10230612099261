import React from "react";
import styles from "./Rodape.module.css";
import { useNavigate } from "react-router-dom";
import Facebook from "../../images/facebook.svg";
import Instagram from "../../images/instagram.svg";
import X from "../../images/x.svg";

function Rodape() {
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={styles.copyContainer}>
        <div>
          <span className={styles.copy}>&copy; 2025 Finant. Todos os direitos reservados.</span>
        </div>
        <div className={styles.termosContainer}>
          <span className={styles.termos} onClick={() => navigate("/termos-e-politicas")}>
            Termo de Serviço
          </span>
          <span className={styles.termos} onClick={() => navigate("/termos-e-politicas")}>
            Política de Privacidade
          </span>
        </div>
      </div>
      <div className={styles.socials}>
        <img src={Facebook} alt="Facebook" className={styles.social} onClick={() => window.open("https://facebook.com/", "_blank")} />
        <img src={Instagram} alt="Instagram" className={styles.social} onClick={() => window.open("https://instagram.com/", "_blank")} />
        <img src={X} alt="X" className={styles.social} onClick={() => window.open("https://x.com/", "_blank")} />
      </div>
    </footer>
  );
}

export default Rodape;

