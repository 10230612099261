import { useNavigate } from "react-router-dom";
import Logotipo from "./logotipo/Logotipo";
import Menu from "./menu/Menu";
import styles from "./MenuLateral.module.css";
import { useState } from "react";

const menus = [
  { icone: "dashboard", nome: "Dashboard", url: "/dashboard", submenu: [] },
  { icone: "currency_exchange", nome: "Movimentações", url: "/movimentacoes", submenu: [] },
  { icone: "task_alt", nome: "Metas Financeiras", url: "/metas", submenu: [] },
  { icone: "assured_workload", nome: "Contas", url: "/contas", submenu: [] },
  { icone: "credit_card", nome: "Cartões de Crédito", url: "/cartoes", submenu: [] },
  { icone: "category", nome: "Categorias", url: "/categorias", submenu: [] },
  { icone: "rocket_launch", nome: "Guias", url: "/guias", submenu: [] }
];

export default function MenuLateral() {
  const [menuExpandido, setMenuExpandido] = useState("");

  const navigate = useNavigate();

  function handleNavigate(nome: string, url: string) {
    if (url === "") {
      if (menuExpandido === nome) {
        setMenuExpandido("");
      } else {
        setMenuExpandido(nome);
      }
      return;
    }

    setMenuExpandido("");
    navigate(url);
  }

  function handleLogoff() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logotipo}>
          <img src={require("../../../images/logo192white.png")} alt="Logo" className={styles.logotipoImagem} />
          Finant
        </div>
        <div className={styles.lista}>
          {menus.map((menu, index) => (
            <Menu
              key={index}
              icone={menu.icone}
              nome={menu.nome}
              url={menu.url}
              submenu={menu.submenu}
              expandido={menuExpandido}
              handleNavigate={handleNavigate}
            />
          ))}
        </div>
        <Menu icone={"person"} nome={"Perfil"} url={"/perfil"} submenu={[]} expandido={menuExpandido} handleNavigate={handleNavigate} />
        <div className={styles.sair}>
          <Menu icone="logout" nome="Sair" url="" submenu={[]} expandido="" handleNavigate={handleLogoff} />
        </div>
      </div>
    </div>
  );
}

