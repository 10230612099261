/* eslint-disable prefer-const */
import { useEffect, useState } from "react";
import Botao from "../../../componentes/botao/Botao";
import TextField from "../../../componentes/textField/TextField";
import styles from "./Transacao.module.css";
import DropDown from "../../../componentes/dropdown/DropDown";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import { useContas } from "../../../servicos/contas/useContas";
import CheckBox, { CheckBoxSize } from "../../../componentes/checkbox/CheckBox";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { Transacao as TransacaoType } from "../../../servicos/transacoes/typeTransacao";
import { textoParaValor } from "../../../utils/Utils";
import Categoria from "../categoria/Cadastro/Formulario";
import Conta from "../conta/Cadastro/Formulario";
import Cartao from "../cartaoCredito/Cadastro/Formulario";
import { useCartaoCreditos } from "../../../servicos/cartaoCredito/useCartaoCredito";
import Alerta, { OpcoesAlerta } from "../../../componentes/alerta/Alerta";

type TransacaoProps = {
  transacao?: TransacaoType;
  setTransacao?: (transacao: TransacaoType | null) => void;
  onClose: () => void;
  tipo: "D" | "R";
};

const tipoPagamento = [
  { icone: "payments", valor: "PIX", texto: "PIX" },
  { icone: "attach_money", valor: "Dinheiro", texto: "Dinheiro" },
  { icone: "credit_card", valor: "Cartão de Crédito", texto: "Cartão de Crédito" },
  { icone: "credit_card", valor: "Cartão de Debito", texto: "Cartão de Debito" },
  { icone: "receipt", valor: "Boleto", texto: "Boleto" },
  { icone: "account_balance", valor: "Transferência", texto: "Transferência" },
  { icone: "lunch_dining", valor: "VR", texto: "Vale Refeição" },
  { icone: "restaurant", valor: "VA", texto: "Vale Alimentação" }
];
export default function Transacao({ transacao, setTransacao, tipo, onClose }: TransacaoProps) {
  const [dados, setDados] = useState<TransacaoType>({
    valor: 0,
    data: new Date(),
    tipo: tipo,
    parcela: 1,
    totalParcelas: 2,
    cartao_credito_id: undefined
  });

  const { categorias } = useCategorias();
  const { contas } = useContas();
  const { cartoes } = useCartaoCreditos();
  const { loading, createTransacao, updateTransacao, deleteTransacao } = useTransacoes();
  const [cadastroAberto, setCadastroAberto] = useState("");
  const [dicaAberta, setDicaAberta] = useState("");
  const [mostrarAvisoExclusao, setMostrarAvisoExclusao] = useState(false);
  const [opcaoEdicao, setOpcaoEdicao] = useState("somenteEsta");

  const todosPreenchidos = !!(dados.valor && dados.data && dados.descricao && dados.categoria_id && dados.tipo_pagamento);

  const handleLimpar = () => {
    setDados({
      id: undefined,
      valor: 0,
      descricao: "",
      data: dados.data,
      tipo_pagamento: dados.tipo_pagamento,
      categoria_id: dados.categoria_id,
      conta_id: dados.conta_id,
      tipo: tipo,
      parcela: 1,
      totalParcelas: 2,
      cartao_credito_id: dados.cartao_credito_id
    });

    if (setTransacao) {
      setTransacao(null);
    }
  };

  const handleExcluir = async (id?: number) => {
    if (id === undefined || id === null || id === 0 || loading) return;
    await deleteTransacao(id, opcaoEdicao);
    onClose();
  };

  const handleFormaPagamento = (tipoPagamento: string) => {
    if (tipoPagamento !== "Cartão de Crédito") {
      setDados({ ...dados, tipo_pagamento: tipoPagamento, cartao_credito_id: undefined });
    } else {
      setDados({ ...dados, tipo_pagamento: tipoPagamento });
    }
  };

  const handleSalvar = async () => {
    if (dados.valor === 0 || loading) {
      return;
    }

    if (!todosPreenchidos) {
      return;
    }

    if (transacao) {
      if (transacao.id !== undefined) {
        await updateTransacao(transacao.id, {
          ...dados,
          valor: textoParaValor(dados.valor.toString()),
          modoEdicao: opcaoEdicao
        });
      }
    } else {
      await createTransacao({
        ...dados,
        valor: textoParaValor(dados.valor.toString())
      });
    }

    handleLimpar();
  };

  const opcoesCategoria = categorias
    .filter((categoria) => categoria.tipo === tipo)
    .map((categoria) => {
      return { icone: categoria.icone, valor: categoria.id?.toString() || "", texto: categoria.nome };
    })
    .sort((a, b) => a.texto.localeCompare(b.texto));

  const opcoesConta = contas.map((conta) => {
    return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
  });

  useEffect(() => {
    if (!transacao) {
      return;
    }

    setDados({ ...transacao });
  }, [transacao]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.campos}>
          {transacao && (dados.parcelamento_id || dados.despesa_fixa_id) && (
            <div className={styles.atencao}>
              <span className={styles.atencaoTitulo}>
                Este lançamento é {dados.parcelamento_id ? "um parcelamento" : "uma depesa fixa"}, você deseja editar/excluir:{" "}
              </span>
              <div className={styles.atencaoOpcoes}>
                <CheckBox
                  label="Somente este lançamento"
                  checked={opcaoEdicao === "somenteEsta"}
                  setChecked={() => setOpcaoEdicao("somenteEsta")}
                  enabled={true}
                  size={CheckBoxSize.SMALL}
                />
                <CheckBox
                  label="Lançamentos futuros (inclusive este)"
                  checked={opcaoEdicao === "futuras"}
                  setChecked={() => setOpcaoEdicao("futuras")}
                  enabled={true}
                  size={CheckBoxSize.SMALL}
                />
                <CheckBox
                  label="Todas as lançamentos"
                  checked={opcaoEdicao === "todas"}
                  setChecked={() => setOpcaoEdicao("todas")}
                  enabled={true}
                  size={CheckBoxSize.SMALL}
                />
              </div>
            </div>
          )}
          <TextField
            type="date"
            label="Data"
            value={dados.data}
            onChange={(data) => setDados({ ...dados, data: data })}
            enabled={opcaoEdicao === "somenteEsta" || !transacao}
            obrigatorio={true}
          />
          <TextField
            type="text"
            label="Descrição"
            value={dados.descricao}
            onChange={(descricao) => setDados({ ...dados, descricao: descricao })}
            enabled={true}
            obrigatorio={true}
          />
          <TextField
            type="currency"
            label={"Valor da " + (tipo === "D" ? "Despesa" : "Receita")}
            value={dados.valor.toString()}
            onChange={(valor) => setDados({ ...dados, valor: valor })}
            enabled={true}
            obrigatorio={true}
          />
          <DropDown
            label={"Forma de " + (tipo === "D" ? "Pagamento" : "Recebimento")}
            opcoes={tipoPagamento}
            valor={dados.tipo_pagamento}
            onChange={(tipo_pagamento) => handleFormaPagamento(tipo_pagamento)}
            obrigatorio={true}
          />
          {dados.tipo_pagamento === "Cartão de Crédito" && (
            <div className={styles.linha}>
              <DropDown
                label="Cartão de Crédito"
                opcoes={cartoes.map((cartao) => {
                  return { icone: "", valor: cartao.id?.toString() || "", texto: cartao.nome };
                })}
                valor={dados.cartao_credito_id?.toString()}
                onChange={(cartao) => setDados({ ...dados, cartao_credito_id: parseInt(cartao) })}
                obrigatorio={true}
              />
              <span className={"material-symbols-rounded " + styles.adicionar} onClick={() => setCadastroAberto("Cartao")}>
                add
              </span>
            </div>
          )}
          {dados.tipo_pagamento !== "Cartão de Crédito" && dados.tipo_pagamento !== "Dinheiro" && (
            <div className={styles.linha}>
              <DropDown
                label="Conta"
                opcoes={opcoesConta}
                valor={dados.conta_id?.toString()}
                onChange={(conta) => setDados({ ...dados, conta_id: parseInt(conta) })}
                obrigatorio={true}
              />
              <span
                className={"material-symbols-rounded " + styles.adicionar}
                onClick={() => setCadastroAberto("Conta")}
                onMouseOver={() => setDicaAberta("Conta")}
                onMouseOut={() => setDicaAberta("")}
              >
                add
              </span>
              <div className={styles.dica} style={{ display: dicaAberta === "Conta" ? "block" : "none" }}>
                Criar uma nova conta.
              </div>
            </div>
          )}
          <div className={styles.linha}>
            <DropDown
              label="Categoria"
              opcoes={opcoesCategoria}
              valor={dados.categoria_id?.toString()}
              onChange={(categoria) => setDados({ ...dados, categoria_id: parseInt(categoria) })}
              obrigatorio={true}
            />
            <span
              className={"material-symbols-rounded " + styles.adicionar}
              onClick={() => setCadastroAberto("Categoria")}
              onMouseOver={() => setDicaAberta("Categoria")}
              onMouseOut={() => setDicaAberta("")}
            >
              add
            </span>
            <div className={styles.dica} style={{ display: dicaAberta === "Categoria" ? "block" : "none" }}>
              Criar uma nova categoria.
            </div>
          </div>
          {!transacao && !dados.eh_parcelada && (
            <div className={styles.linha}>
              <CheckBox
                label={"Despesa fixa?"}
                icon={"event_repeat"}
                checked={dados.eh_fixa || false}
                setChecked={(eh_fixa) => setDados({ ...dados, eh_fixa: eh_fixa })}
                enabled={!transacao}
              />
              <span
                className={"material-symbols-rounded " + styles.adicionar}
                onMouseOver={() => setDicaAberta("DespesaFixa")}
                onMouseOut={() => setDicaAberta("")}
              >
                question_mark
              </span>
              <div className={styles.dica} style={{ display: dicaAberta === "DespesaFixa" ? "block" : "none" }}>
                Marque esta opção para que esta despesa seja lançada automaticamente pelos proximos 24 meses.
              </div>
            </div>
          )}
          {!transacao && dados.tipo_pagamento === "Cartão de Crédito" && !dados.eh_fixa && (
            <CheckBox
              label={"Este lançamento será parcelado?"}
              icon={"event_repeat"}
              checked={dados.eh_parcelada || false}
              setChecked={(foiParcelada) => setDados({ ...dados, eh_parcelada: foiParcelada })}
              enabled={!transacao}
            />
          )}
          {!transacao && dados.eh_parcelada && (
            <div className={styles.parcelas}>
              <TextField
                type="number"
                label="Parcela"
                value={dados.parcela}
                onChange={(parcela) => setDados({ ...dados, parcela: parseInt(parcela) })}
                enabled={!transacao}
              />
              {!transacao && (
                <TextField
                  type="number"
                  label="De"
                  value={dados.totalParcelas}
                  onChange={(totalParcelas) => setDados({ ...dados, totalParcelas: parseInt(totalParcelas) })}
                  enabled={!transacao}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.botoes}>
          <Botao
            texto={!loading ? "Salvar" : ""}
            icone={!loading ? "check" : "progress_activity"}
            spin={loading}
            onClick={() => handleSalvar()}
            enabled={todosPreenchidos}
          />
          {transacao && transacao.id && !loading && (
            <Botao
              texto="Excluir"
              icone={"delete"}
              background="#D11A2A"
              color="white"
              onClick={() => setMostrarAvisoExclusao(true)}
              enabled={true}
            />
          )}
        </div>
      </div>
      {cadastroAberto === "Categoria" && <Categoria categoria={null} fecharModal={() => setCadastroAberto("")} escurecerFundo={false} />}
      {cadastroAberto === "Conta" && <Conta conta={null} fecharModal={() => setCadastroAberto("")} escurecerFundo={false} />}
      {cadastroAberto === "Cartao" && <Cartao cartao={null} fecharModal={() => setCadastroAberto("")} escurecerFundo={false} />}
      <Alerta
        titulo="Excluir Movimentação"
        descricao="Deseja realmente excluir esse lançamento?"
        onClose={() => setMostrarAvisoExclusao(false)}
        onConfirm={() => handleExcluir(transacao?.id)}
        visivel={mostrarAvisoExclusao}
        opcoes={OpcoesAlerta.SIM_NAO}
      />
    </div>
  );
}

