import styles from "./MonthPicker.module.css";

type MonthPickerProps = {
  dataSelecionada: Date;
  setDataSelecionada: (data: Date) => void;
};

export default function MonthPicker(props: MonthPickerProps) {
  const { dataSelecionada, setDataSelecionada } = props;

  const mesFormatado = dataSelecionada.toLocaleString("pt-BR", { month: "long", year: "numeric" }).replace(" de ", " ");

  const handleMesAnterior = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() - 1);
    setDataSelecionada(novoMes);
  };

  const handleProximoMes = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() + 1);
    setDataSelecionada(novoMes);
  };

  return (
    <div className={styles.meses}>
      <div className={styles.botaoAcao} onClick={handleMesAnterior}>
        <span className="material-symbols-rounded">chevron_left</span>
      </div>
      <span className={styles.textoAcao}>{mesFormatado.charAt(0).toUpperCase() + mesFormatado.slice(1)}</span>
      <div className={styles.botaoAcao} onClick={handleProximoMes}>
        <span className="material-symbols-rounded">chevron_right</span>
      </div>
    </div>
  );
}

