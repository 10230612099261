import { useEffect, useRef } from "react";
import styles from "./Alerta.module.css";
import Botao from "../botao/Botao";

// Enum para combinações de opções
export enum OpcoesAlerta {
  SIM_NAO = "Sim,Não",
  SIM_NAO_CANCELAR = "Sim,Não,Cancelar",
  OK = "OK"
}

type AlertaProps = {
  titulo: string;
  descricao: string;
  visivel: boolean;
  onConfirm?: () => void;
  onClose: () => void;
  opcoes?: OpcoesAlerta; // Utilizando o enum para definir as opções
};

export default function Alerta({
  titulo,
  descricao,
  visivel,
  onConfirm,
  onClose,
  opcoes = OpcoesAlerta.SIM_NAO // Valor padrão
}: AlertaProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClose]);

  if (!visivel) {
    return null;
  }

  // Converte as opções do enum para um array
  const botoes = opcoes.split(",");

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} ref={ref}>
        <div className={styles.titulo}>{titulo}</div>
        <div className={styles.conteudo}>{descricao}</div>
        <div className={styles.botoes}>
          {botoes.map((texto, index) => (
            <Botao
              texto={texto}
              key={index}
              color={texto === "Sim" ? "#333333" : "#333333"}
              background={texto === "Sim" ? "#ffd862" : "#f5f5f5"}
              onClick={() => {
                if (texto === "Sim" && onConfirm) {
                  onConfirm();
                }
                onClose();
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

