import React from "react";
import styles from "./Cabecalho.module.css";
import FormularioLogin from "../login/formularioLogin/FormularioLogin";

function Cabecalho() {
  return (
    <header className={styles.header}>
      <img src={require("../../images/logo192white.png")} alt="Finant" className={styles.logoImage} />
      <div className={styles.container}>
        <h1 className={styles.title}>
          <div className={styles.tituloPrincipal}>
            <span>Você no controle da sua</span>
            <span>vida financeira.</span>
          </div>
          <div className={styles.subtitulo}>
            <span>Transforme o jeito como você gerencia suas finanças,</span>
            <span>de forma prática e inteligente.</span>
          </div>
        </h1>
        <div className={styles.formulario}>
          <FormularioLogin />
        </div>
      </div>
    </header>
  );
}

export default Cabecalho;

