import { createContext, useContext } from "react";
import { Meta } from "../servicos/metas/typeMeta";

type MetaContextType = {
  metas: Meta[];
  setMetas: React.Dispatch<React.SetStateAction<Meta[]>>;
};

export const MetaContexto = createContext<MetaContextType | undefined>(undefined);

export const useMetaContexto = () => {
  const context = useContext(MetaContexto);
  if (!context) {
    throw new Error("useMetaContexto deve ser usado dentro de um MetaContexto.Provider");
  }
  return context;
};

