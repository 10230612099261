import { useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./RecuperarSenha.module.css";
import Botao from "../../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import Logotipo from "../../estruturais/menuLateral/logotipo/Logotipo";
import useAvisos from "../../../hooks/useAviso";
import Aviso from "../../estruturais/aviso/Aviso";
import useFirebase from "../../../hooks/useFirebase";

export default function RecuperarSenha() {
  const [dados, setDados] = useState({ email: "" });

  const { avisos, adicionarAviso } = useAvisos();
  const { resetPassword } = useFirebase();

  const navigate = useNavigate();

  const handleRecuperarSenha = () => {
    resetPassword(dados.email);
  };

  return (
    <div className={styles.container}>
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar} onClick={() => navigate("/login")}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Redefinir senha</div>
          <div className={styles.formulario}>
            <div className={styles.dica}>Digite o e-mail cadastrado para receber as instruções de redefinição de senha.</div>
            <TextField
              label="Digite o e-mail cadastrado"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
            />
            <Botao texto="Continuar" onClick={() => handleRecuperarSenha()} background="#ffd862" color="#333333" />
          </div>
        </div>
      </div>
    </div>
  );
}

