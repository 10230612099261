import { useState } from "react";
import styles from "./Accordion.module.css";

type AccordionProps = {
  nome: string;
  habilitado?: boolean;
  children?: any;
  expandidoDefault?: boolean;
};

export default function Accordion({ expandidoDefault = true, ...props }: AccordionProps) {
  const [expandido, setExpandido] = useState(expandidoDefault);

  const handleExpandido = () => {
    if (!props.habilitado) return;

    setExpandido(!expandido);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={styles.titulo}
          onClick={handleExpandido}
          style={{ cursor: props.habilitado ? "pointer" : "default", borderBottom: props.nome ? "1px solid #353535" : "none" }}
        >
          {props.nome}
          {props.habilitado && <span className="material-icons">{expandido ? "expand_less" : "expand_more"}</span>}
        </div>
        {expandido && <div className={styles.conteudo}>{props.children}</div>}
      </div>
    </div>
  );
}

