import MenuLateral from "../menuLateral/MenuLateral";
import PainelCentral from "../painelCentral/PainelCentral";
import styles from "./Pagina.module.css";
import Transacao from "../../cadastros/transacao/Transacao";
import { useNavigate } from "react-router-dom";
import Slider from "../../../componentes/slider/Slider";
import TransferenciaConta from "../../cadastros/transferenciaConta/TransferenciaConta";
import useAvisos from "../../../hooks/useAviso";
import Aviso from "../aviso/Aviso";
import Loader from "../../loader/Loader";
import { usePerfis } from "../../../servicos/perfis/usePerfil";

export default function Pagina(props: { icone: string; pagina: string; children: React.ReactNode }) {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const { avisos } = useAvisos();
  const { loading } = usePerfis();

  const fecharModal = () => {
    navigate(window.location.pathname);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.container}>
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
      <div className={styles.wrapper}>
        <MenuLateral />
        <div className={styles.conteudo}>
          <PainelCentral>{props.children}</PainelCentral>
        </div>
      </div>
      {urlParams?.get("lancamento") === "despesa" && (
        <Slider titulo="Adicionar despesa" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <Transacao onClose={() => fecharModal()} tipo="D" />
        </Slider>
      )}
      {urlParams?.get("lancamento") === "receita" && (
        <Slider titulo="Adicionar receita" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <Transacao onClose={() => fecharModal()} tipo="R" />
        </Slider>
      )}
      {urlParams?.get("lancamento") === "transferencia" && (
        <Slider titulo="Transferência entra contas" subtitulo="Movimentações" onClose={() => fecharModal()}>
          <TransferenciaConta onClose={() => fecharModal()} />
        </Slider>
      )}
    </div>
  );
}

