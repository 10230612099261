import styles from "./Loader.module.css";

export default function Loader() {
  return (
    <div className={styles.loader}>
      <img src={require("../../images/logo192white.png")} alt="Carregando..." className={styles.image} />
    </div>
  );
}

