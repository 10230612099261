import { useEffect, useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./Meta.module.css";
import { useMetas } from "../../../servicos/metas/useMetas";
import Cadastro from "./Cadastro/Cadastro";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import { extrairMesAno, valorParaTexto } from "../../../utils/Utils";
import Tabela, { Coluna } from "../../../componentes/tabela/Tabela";
import MonthPicker from "../../../componentes/monthPicker/MonthPicker";

const isMobile = window.innerWidth < 768;

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "Categoria", accessor: "categoria", size: "40%" },
  { header: "Meta", accessor: "valor", align: "center", type: "currency", size: "15%" },
  { header: "Gasto", accessor: "valorGasto", align: "center", type: "currency", size: "15%" },
  { header: "Restante", accessor: "valorRestante", align: "center", type: "currency", size: "15%" },
  { header: "Utilizado (%)", accessor: "percentualUtilizado", align: "center", type: "percentage", size: "15%" }
];

export default function Meta() {
  const { metas, fetchMetas } = useMetas();
  const [selecionada, setSelecionada] = useState<any>(null);
  const [dataSelecionada, setDataSelecionada] = useState(new Date());

  const totalGasto = metas.reduce((acc, meta) => acc + parseFloat(meta.valorGasto?.toString() || "0"), 0);
  const totalMeta = metas.reduce((acc, meta) => acc + parseFloat(meta.valor?.toString() || "0"), 0);
  const percentualTotal = totalMeta > 0 ? (totalGasto / totalMeta) * 100 : 0;
  const corPercentual = percentualTotal <= 100 ? "#198000" : "#df0101";

  const handleSelecionar = (row: any) => {
    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    setSelecionada(row);
  };

  const handleFecharModal = () => {
    setSelecionada(null);
    fetchMetas(extrairMesAno(dataSelecionada));
  };

  const dados = metas.map((meta) => {
    return {
      id: meta.id,
      categoria: meta.categoria,
      valor: meta.valor,
      valorGasto: (meta.valorGasto || 0) * -1,
      valorRestante: meta.valorRestante || 0,
      percentualUtilizado: `${meta.percentualUtilizado}%`,
      categoria_id: meta.categoria_id,
      mesAno: meta.mesAno
    };
  });

  useEffect(() => {
    if (!dataSelecionada) {
      return;
    }

    fetchMetas(extrairMesAno(dataSelecionada));
  }, [dataSelecionada]);

  return (
    <Pagina icone="target" pagina="Minhas Metas Financeiras">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Metas Financeiras</div>
            <Cadastro meta={selecionada} setMeta={handleFecharModal} />
          </div>
          <div className={styles.cabecalho}>
            <Informativo>
              <span>Gerencie suas metas financeiras e acompanhe seu desempenho.</span>
            </Informativo>
            <MonthPicker dataSelecionada={dataSelecionada} setDataSelecionada={setDataSelecionada} />
          </div>
          <ResumoSuperior titulo="Resumo das metas">
            <ResumoDash titulo="Total de Metas" cor="#198000">
              {valorParaTexto(totalMeta, true)}
            </ResumoDash>
            <ResumoDash titulo="Total Gasto" cor="#df0101">
              {valorParaTexto(totalGasto, true)}
            </ResumoDash>
            <ResumoDash titulo="Utilizado" cor={corPercentual}>
              {percentualTotal.toFixed(2)}%
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.painel}>
            <Tabela selecionada={selecionada} colunas={colunas} dados={dados} onRowClick={handleSelecionar} />
          </div>
        </div>
      </div>
    </Pagina>
  );
}

