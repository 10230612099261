import React from "react";
import styles from "./AcaoLogin.module.css";
import { useNavigate } from "react-router-dom";
import Botao from "../../componentes/botao/Botao";

function AcaoLogin() {
  const navigator = useNavigate();

  return (
    <section className={styles.section}>
      <div className={styles.conteudo}>
        <div className={styles.painel} />
        <div className={styles.painel}>
          <span className={styles.titulo}>Crie sua conta agora, totalmente de graça!</span>
          <span className={styles.subtitulo}>
            Agora que você conhece o Finant e viu como ele pode te ajudar, que tal começar a usar agora mesmo?
          </span>
          <div className={styles.acao}>
            <Botao texto="Criar conta agora" onClick={() => navigator("/criar-conta")} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AcaoLogin;

