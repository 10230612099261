import { useState } from "react";
import { app, analytics, auth } from "../index"; // Certifique-se de importar o auth corretamente
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from "firebase/auth";
import useAvisos from "./useAviso";
import { usePerfis } from "../servicos/perfis/usePerfil";

export default function useFirebase() {
  const [loading, setLoading] = useState(false);
  const googleProvider = new GoogleAuthProvider();
  const { adicionarAviso } = useAvisos();
  const { createPerfil } = usePerfis(false);

  // Função para login com email e senha
  function loginWithCredentials(email: string, senha: string) {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        setLoading(false);
        return userCredential.user;
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/invalid-credential") {
          adicionarAviso("Usuário ou senha inválidos", "erro");
        } else {
          adicionarAviso("Erro ao fazer login", "erro");
        }
      });
  }

  // Função para criação de conta com email e senha
  function register(email: string, senha: string) {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, senha)
      .then((userCredential) => {
        setLoading(false);
        return userCredential.user;
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/email-already-in-use") {
          adicionarAviso("E-mail já cadastrado", "erro");
        }
        if (error.code === "auth/weak-password") {
          adicionarAviso("Senha fraca", "erro");
        }

        if (error.code === "auth/invalid-email") {
          adicionarAviso("O E-mail informado é inválido", "erro");
        }

        if (error.code === "auth/password-does-not-meet-requirements") {
          adicionarAviso("A senha deve conter um caractere minúsculo, um caractere maiúsculo e um numero", "erro");
        }
      });
  }

  // Função para login com Google (cria a conta automaticamente se não existir)
  function loginOrRegisterWithGoogle() {
    setLoading(true);
    return signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        const additionalInfo = getAdditionalUserInfo(result);

        if (additionalInfo?.isNewUser) {
          createPerfil(
            {
              nome: user.displayName ?? "",
              telefone: user.phoneNumber ?? "",
              email: user.email ?? "",
              hashCode: user.uid
            },
            true
          ).then(() => {
            setLoading(false);
            return user;
          });
        } else {
          setLoading(false);
          return user;
        }
      })
      .catch((error) => {
        setLoading(false);
        adicionarAviso("Erro ao fazer login com Google", "erro");
      });
  }

  // Função para recuperação de senha
  function resetPassword(email: string) {
    setLoading(true);

    return sendPasswordResetEmail(auth, email)
      .then(() => {
        adicionarAviso("Se este email estiver cadastrado, você receberá um link para redefinir a senha", "info");
        setLoading(false);
        return true; // Retorna true se o email foi enviado com sucesso
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        if (error.code === "auth/invalid-email") {
          adicionarAviso("O E-mail informado é inválido", "erro");
        }

        if (error.code === "auth/missing-email") {
          adicionarAviso("E-mail não informado", "erro");
        }
      });
  }

  // Função para trocar a senha do usuário autenticado
  function changePassword(currentPassword: string, newPassword: string) {
    setLoading(true);

    const user = auth.currentUser; // Pega o usuário autenticado
    if (!user || !user.email) {
      setLoading(false);
      adicionarAviso("Usuário não autenticado", "erro");
      throw new Error("Usuário não autenticado");
    }

    // Criar credencial com o email do usuário e a senha atual
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    // Reautenticar o usuário antes de permitir a troca de senha
    return reauthenticateWithCredential(user, credential)
      .then(() => {
        // Após reautenticar, atualiza a senha
        return updatePassword(user, newPassword)
          .then(() => {
            setLoading(false);
            adicionarAviso("Senha alterada com sucesso", "sucesso");
            return true;
          })
          .catch((error) => {
            setLoading(false);
            if (error.code === "auth/password-does-not-meet-requirements") {
              adicionarAviso("A senha deve conter um caractere minúsculo, um caractere maiúsculo e um numero", "erro");
            } else {
              adicionarAviso("Erro ao alterar a senha", "erro");
            }
          });
      })
      .catch((error) => {
        console.error("Erro na reautenticação:", error.message);
        setLoading(false);

        if (error.code === "auth/wrong-password") {
          adicionarAviso("Senha atual incorreta", "erro");
        } else {
          adicionarAviso("Erro ao reautenticar o usuário", "erro");
        }
      });
  }

  return { loading, auth, loginWithCredentials, changePassword, register, loginOrRegisterWithGoogle, resetPassword };
}

