import React from "react";
import styles from "./Funcionalidades.module.css";

const features = [
  {
    icon: "account_balance",
    title: "Gerencie suas contas",
    feature:
      "Centralize todas as suas contas bancárias em um só lugar. Visualize saldos, transações e organize suas finanças de forma prática."
  },
  {
    icon: "credit_card",
    title: "Controle seus cartões de crédito",
    feature: "Monitore seus gastos com cartões de crédito, acompanhe limites e faturas para nunca mais perder o controle."
  },
  {
    icon: "flag",
    title: "Acompanhe metas de gastos",
    feature: "Defina metas financeiras personalizadas para categorias como alimentação, lazer e transporte, e acompanhe seu progresso."
  },
  {
    icon: "insights",
    title: "Consultas avançadas",
    feature:
      "Dashboards interativos e gráficos detalhados para ajudar você a identificar tendências e tomar decisões financeiras inteligentes."
  },
  {
    icon: "lock",
    title: "Segurança em suas finanças",
    feature: "Tecnologia de ponta para proteger suas informações. Seus dados estão sempre seguros com criptografia avançada."
  },
  {
    icon: "check_circle",
    title: "Facilidade de uso",
    feature: "Interface intuitiva e funcional. Ideal tanto para iniciantes quanto para quem já é especialista em finanças."
  }
];

type FuncionalidadeProps = {
  title: string;
  feature: string;
  icon: string;
};

function Funcionalidade({ title, feature, icon }: FuncionalidadeProps) {
  return (
    <div className={styles.card}>
      <span className={`material-symbols-rounded ${styles.icon}`}>{icon}</span>
      <h3>{title}</h3>
      <p>{feature}</p>
    </div>
  );
}

function Funcionalidades() {
  return (
    <section className={styles.section}>
      <h2>Descubra nossos serviços</h2>
      <div className={styles.cards}>
        {features.map((feature, index) => (
          <Funcionalidade key={index} title={feature.title} feature={feature.feature} icon={feature.icon} />
        ))}
      </div>
    </section>
  );
}

export default Funcionalidades;

