import { useEffect, useState } from "react";
import axios from "axios";
import { Perfil } from "./typePerfil";
import { usePerfilContexto } from "../../contextos/PerfilContexto";
import { useNavigate } from "react-router-dom";
import useAvisos from "../../hooks/useAviso";
import { useContas } from "../contas/useContas";
import { useCategorias } from "../categorias/useCategorias";
import { useTransacoes } from "../transacoes/useTransacoes";
import { useCartaoCreditos } from "../cartaoCredito/useCartaoCredito";
import { useMetas } from "../metas/useMetas";

const API_URL = process.env.REACT_APP_API_URL + "/usuarios";

const paginasRedirect = ["/", "/login", "/criar-conta", "/recuperar-senha"];

export function usePerfis(carregarDados = true) {
  const { perfil, setPerfil } = usePerfilContexto();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { adicionarAviso } = useAvisos();
  const { contas, fetchContas } = useContas();
  const { categorias, fetchCategorias } = useCategorias();
  const { transacoes, fetchTransacoes } = useTransacoes();
  const { cartoes, fetchCartaoCreditos } = useCartaoCreditos();
  const { metas, fetchMetas } = useMetas();

  // Função para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");
  const authenticated = () => !!getToken();

  // Função para buscar o perfil do usuário autenticado
  const autoLogin = async () => {
    const token = getToken();
    if (!token) return;

    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPerfil(response.data);

      if (paginasRedirect.includes(window.location.pathname)) {
        navigate("/dashboard");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("Erro desconhecido.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para login
  const login = async (email: string, hash: string, primeiroAcesso: boolean) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/login`, { email, hash });

      const { token, usuario } = response.data;

      // Armazena o token no localStorage
      localStorage.setItem("token", token);

      // Define o perfil do usuário
      setPerfil(usuario);

      if (primeiroAcesso) {
        navigate("/guias");
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("Erro desconhecido.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para criar um perfil (Autenticada)
  const createPerfil = async (newPerfil: Perfil, primeiroAcesso: boolean) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(API_URL, newPerfil);
      login(newPerfil.email, newPerfil.hashCode, primeiroAcesso);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("Erro desconhecido.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar um perfil (Autenticada)
  const updatePerfil = async (id: number, updatedPerfil: Perfil) => {
    if (!perfil || !perfil.id) {
      return;
    }

    setLoading(true);
    setError(null);

    const token = getToken(); // Obtém o token do localStorage

    if (!token) {
      setError("Autenticação necessária.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedPerfil, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPerfil(response.data);

      adicionarAviso("Informações do perfil atualizados com sucesso!", "sucesso");
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("Erro desconhecido.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar um perfil (Autenticada)
  const deletePerfil = async (id: number) => {
    setLoading(true);
    setError(null);

    const token = getToken(); // Obtém o token do localStorage

    if (!token) {
      setError("Autenticação necessária.");
      setLoading(false);
      return;
    }

    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message);
      } else {
        setError("Erro desconhecido.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    // Verifica se já existe um token e tenta o autoLogin
    const path = window.location.pathname;

    if (authenticated()) {
      if (paginasRedirect.includes(path) || !perfil?.id) {
        autoLogin(); // Chama o autoLogin se o token existir e estiver em uma página de login ou registro
      }
    } else {
      if (!paginasRedirect.includes(path)) {
        navigate("/login");
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (perfil.loaded === true || !carregarDados) {
      return;
    }

    setPerfil({ ...perfil, loaded: true });

    async function fetchAllData() {
      try {
        const promises = [];

        if (!contas || contas.length === 0) {
          promises.push(fetchContas());
        }

        if (!categorias || categorias.length === 0) {
          promises.push(fetchCategorias());
        }

        if (!transacoes || transacoes.length === 0) {
          if (!window.location.pathname.includes("dashboard")) {
            promises.push(fetchTransacoes());
          }
        }

        if (!cartoes || cartoes.length === 0) {
          promises.push(fetchCartaoCreditos());
        }

        if (!metas || metas.length === 0) {
          //pega o mes ano atual no formato YYYY-MM
          const mesAno = new Date().toISOString().slice(0, 7);
          promises.push(fetchMetas(mesAno));
        }

        if (promises.length > 0) {
          setLoading(true);
          await Promise.all(promises); // Aguarda a conclusão de todas as promessas
        }
      } catch (error) {
        adicionarAviso("Erro ao carregar dados", "erro");
      } finally {
        setLoading(false); // Somente definido após todas as promessas serem resolvidas
      }
    }

    fetchAllData();
  }, [perfil.loaded]);

  return {
    perfil,
    loading,
    authenticated,
    createPerfil,
    updatePerfil,
    deletePerfil,
    login
  };
}

