import { BrowserRouter, Routes, Route } from "react-router-dom";
import Perfil from "./telas/cadastros/perfil/Perfil";
import Login from "./telas/login/Login";
import CartaoCredito from "./telas/cadastros/cartaoCredito/CartaoCredito";
import Categoria from "./telas/cadastros/categoria/Categoria";
import Dashboard from "./telas/dashboard/Dashboard";
import Conta from "./telas/cadastros/conta/Conta";
import Transacao from "./telas/transacao/Transacao";
import Configuracao from "./telas/configuracao/Configuracao";
import { ContaContexto } from "./contextos/ContaContexto";
import { useEffect, useState } from "react";
import { Conta as TipoConta } from "./servicos/contas/typeConta";
import { CategoriaContexto } from "./contextos/CategoriaContexto";
import { Categoria as TipoCategoria } from "./servicos/categorias/typeCategoria";
import { TransacaoContexto } from "./contextos/TransacaoContexto";
import { Transacao as TipoTransacao } from "./servicos/transacoes/typeTransacao";
import { PerfilContexto } from "./contextos/PerfilContexto";
import { Perfil as TipoPerfil } from "./servicos/perfis/typePerfil";
import RecuperarSenha from "./telas/login/recuperarSenha/RecuperarSenha";
import CriarConta from "./telas/login/criarConta/CriarConta";
import { AvisoContexto, AvisoType } from "./contextos/AvisoContexto";
import { CartaoCredito as TipoCartao } from "./servicos/cartaoCredito/typeCartaoCredito";
import { CartaoCreditoContexto } from "./contextos/CartaoCreditoContexto";
import { Meta as TipoMeta } from "./servicos/metas/typeMeta";
import { Usuario } from "./servicos/usuarios/typeUsuario";
import Landing from "./telas/landing/Landing";
import Termos from "./telas/termos/Termos";
import Meta from "./telas/cadastros/meta/Meta";
import { auth } from "./index";
import { UsuarioContexto } from "./contextos/UsuarioContexto";
import Guias from "./telas/guias/Guias";
import { MetaContexto } from "./contextos/MetaContexto";

export default function Rotas() {
  const [contas, setContas] = useState<TipoConta[]>([]);
  const [categorias, setCategorias] = useState<TipoCategoria[]>([]);
  const [transacoes, setTransacoes] = useState<TipoTransacao[]>([]);
  const [perfil, setPerfil] = useState<TipoPerfil>({
    email: "",
    hashCode: "",
    loaded: false
  });
  const [avisos, setAvisos] = useState<AvisoType[]>([]);
  const [cartoes, setCartoes] = useState<TipoCartao[]>([]);
  const [usuario, setUsuario] = useState<Usuario>({
    data: null
  });
  const [metas, setMetas] = useState<TipoMeta[]>([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUsuario({ data: user });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <UsuarioContexto.Provider value={{ usuario, setUsuario }}>
        <PerfilContexto.Provider value={{ perfil, setPerfil }}>
          <ContaContexto.Provider value={{ contas, setContas }}>
            <CategoriaContexto.Provider value={{ categorias, setCategorias }}>
              <TransacaoContexto.Provider value={{ transacoes, setTransacoes }}>
                <AvisoContexto.Provider value={{ avisos, setAvisos }}>
                  <CartaoCreditoContexto.Provider value={{ cartoes, setCartoes }}>
                    <MetaContexto.Provider value={{ metas, setMetas }}>
                      <Routes>
                        <Route path="/cartoes" element={<CartaoCredito />} />
                        <Route path="/categorias" element={<Categoria />} />
                        <Route path="/contas" element={<Conta />} />
                        <Route path="/" element={<Landing />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                        <Route path="/criar-conta" element={<CriarConta />} />
                        <Route path="/perfil" element={<Perfil />} />
                        <Route path="/movimentacoes" element={<Transacao />} />
                        <Route path="/configuracoes" element={<Configuracao />} />
                        <Route path="/termos-e-politicas" element={<Termos />} />
                        <Route path="/guias" element={<Guias />} />
                        <Route path="/metas" element={<Meta />} />
                      </Routes>
                    </MetaContexto.Provider>
                  </CartaoCreditoContexto.Provider>
                </AvisoContexto.Provider>
              </TransacaoContexto.Provider>
            </CategoriaContexto.Provider>
          </ContaContexto.Provider>
        </PerfilContexto.Provider>
      </UsuarioContexto.Provider>
    </BrowserRouter>
  );
}

