import React from "react";
import styles from "./Tabela.module.css";
import { valorParaTexto } from "../../utils/Utils";

export type Coluna = {
  header: string;
  accessor: string;
  visible?: boolean;
  type?: string;
  size?: string;
  align?: "left" | "right" | "center";
};

type Dados = {
  [key: string]: any;
};

type TabelaProps = {
  selecionada?: Dados;
  colunas: Coluna[];
  dados: Dados[];
  onRowClick?: (row: Dados) => void;
};

const formatarValor = (valor: any, type: string, hint: boolean) => {
  if (valor instanceof Date) {
    return valor.toLocaleDateString();
  }
  if (type === "cor") {
    return hint ? "" : <div style={{ width: "24px", height: "24px", backgroundColor: valor, borderRadius: "50%" }} />;
  }
  if (type === "icone") {
    return hint ? "" : <span className={styles.icone + " material-symbols-rounded"}>{valor}</span>;
  }
  if (type === "currency") {
    if (hint) return valorParaTexto(parseFloat(valor));
    const color = valor < 0 ? "#df0101" : valor === 0 ? "#353535" : "#198000";
    return <div style={{ color }}>{valorParaTexto(Math.abs(parseFloat(valor)))}</div>;
  }
  if (type === "account") {
    const [icon, text, cor] = valor.split("|");
    return hint ? (
      text
    ) : (
      <div className={styles.icone}>
        <img src={icon} alt={text} style={{ backgroundColor: cor || "" }} />
      </div>
    );
  }
  if (type === "text_icon") {
    const [icon, text, cor] = valor.split("|");
    return hint ? (
      text
    ) : (
      <div className={styles.icone + " material-symbols-rounded"} style={{ color: cor || "" }}>
        {icon}
      </div>
    );
  }
  if (type === "transaction_type") {
    if (hint) return valor === "R" ? "ENTRADA" : "SAÍDA";
    const bgColor = valor === "R" ? "#198000" : "#cc4400";
    return (
      <div className={styles.flexMiddle}>
        <div className={styles.transacao} style={{ backgroundColor: bgColor }}>
          {valor === "R" ? "ENTRADA" : "SAÍDA"}
        </div>
      </div>
    );
  }
  if (type === "image") {
    return hint ? "" : <img src={valor} alt="Imagem" />;
  }
  return valor;
};

const Tabela = ({ selecionada, colunas, dados, onRowClick }: TabelaProps) => {
  return (
    <>
      {dados.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              {colunas
                .filter((c) => c.visible !== false)
                .map((coluna) => (
                  <th
                    className={styles.th}
                    key={coluna.accessor}
                    style={{
                      width: coluna.size || "",
                      textAlign: coluna.align || "left"
                    }}
                  >
                    {coluna.header}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {dados.map((row, idx) => (
              <tr
                key={idx}
                onClick={() => onRowClick && onRowClick(row)}
                style={{ backgroundColor: selecionada?.id === row.id ? "#C5E8F4" : "" }}
              >
                {colunas
                  .filter((c) => c.visible !== false)
                  .map((coluna) => (
                    <td
                      key={coluna.accessor}
                      className={styles.td}
                      style={{
                        width: coluna.size || "",
                        textAlign: coluna.align || "left"
                      }}
                      title={formatarValor(row[coluna.accessor], coluna.type || "", true)}
                    >
                      {formatarValor(row[coluna.accessor], coluna.type || "", false)}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.listaVazia}>Nenhum registro encontrado.</div>
      )}
    </>
  );
};

export default Tabela;

