import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Rotas from "./Rotas";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQQBpcqJsQhIaCpKBWmydLsCWyHtKmY9g",
  authDomain: "finant-8e4bc.firebaseapp.com",
  projectId: "finant-8e4bc",
  storageBucket: "finant-8e4bc.firebasestorage.app",
  messagingSenderId: "135107212701",
  appId: "1:135107212701:web:8fcb10573433d9f6412b74",
  measurementId: "G-PBD45N6W7H"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

root.render(<Rotas />);

