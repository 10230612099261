import { useState } from "react";
import Abas from "../../componentes/abas/Abas";
import Rodape from "../landing/Rodape";
import styles from "./Termos.module.css";
import { useNavigate } from "react-router-dom";

export default function Termos() {
  const [abas, setAbas] = useState(["Termo de Serviço", "Política de Privacidade"]);
  const [abaSelecionada, setAbaSelecionada] = useState(abas[0]);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.cabecalho}>
        <img src={require("../../images/logo192white.png")} alt="Finant" className={styles.logoImage} onClick={() => navigate("/")} />
        <div className={styles.titulo}>Políticas e Termos de Serviço</div>
      </div>
      <div className={styles.corpo}>
        <Abas abas={abas} setAbas={setAbas} setAbaSelecionada={setAbaSelecionada} abaSelecionada={abaSelecionada} />
        {abaSelecionada === "Termo de Serviço" ? (
          <div className={styles.conteudo}>
            <div className={styles.conteudoTitulo}>Termo de Serviço</div>

            <div className={styles.conteudoTopico}>1. Aceitação dos Termos</div>
            <div className={styles.conteudoTexto}>
              Ao acessar e utilizar a plataforma Finant, o usuário concorda que leu, entendeu e aceitou integralmente os termos e condições
              aqui descritos. Caso não concorde com alguma parte deste documento, o usuário deve interromper imediatamente o uso da
              plataforma.
            </div>

            <div className={styles.conteudoTopico}>2. Sobre a Plataforma</div>
            <div className={styles.conteudoTexto}>
              O Finant é uma plataforma digital voltada ao gerenciamento financeiro pessoal, permitindo que os usuários registrem e
              acompanhem suas movimentações financeiras, contas, cartões de crédito, metas de gastos e recebimentos. A plataforma é
              oferecida "no estado em que se encontra" e pode ser atualizada, modificada ou descontinuada a qualquer momento, sem prévio
              aviso ao usuário.
            </div>

            <div className={styles.conteudoTopico}>3. Cadastro e Responsabilidades do Usuário</div>
            <div className={styles.conteudoTexto}>
              3.1. Para utilizar determinadas funcionalidades da plataforma, o usuário deve se cadastrar, fornecendo informações precisas,
              completas e atualizadas.
              <br />
              3.2. O usuário é o único responsável pela veracidade dos dados fornecidos e pela segurança de suas credenciais de acesso,
              comprometendo-se a não compartilhar sua senha com terceiros.
              <br />
              3.3. Em caso de uso indevido da conta, o usuário deve notificar imediatamente a equipe de suporte do Finant.
            </div>

            <div className={styles.conteudoTopico}>4. Uso Adequado da Plataforma</div>
            <div className={styles.conteudoTexto}>
              4.1. O usuário compromete-se a utilizar a plataforma exclusivamente para fins legais e em conformidade com este termo de
              serviço.
              <br />
              4.2. É expressamente proibido:
              <ul>
                <li>Utilizar a plataforma para praticar qualquer atividade ilícita;</li>
                <li>Tentar obter acesso não autorizado a sistemas do Finant;</li>
                <li>Realizar qualquer ação que comprometa a segurança ou o funcionamento da plataforma.</li>
              </ul>
            </div>

            <div className={styles.conteudoTopico}>5. Privacidade e Proteção de Dados</div>
            <div className={styles.conteudoTexto}>
              5.1. O Finant compromete-se a proteger os dados pessoais dos usuários, conforme descrito na nossa Política de Privacidade.
              <br />
              5.2. Os dados coletados serão utilizados apenas para fins de operação e melhoria da plataforma, garantindo-se a não divulgação
              de informações pessoais a terceiros, salvo em casos exigidos por lei.
            </div>

            <div className={styles.conteudoTopico}>6. Limitação de Responsabilidade</div>
            <div className={styles.conteudoTexto}>
              6.1. O Finant não garante que a plataforma estará disponível de forma ininterrupta ou livre de erros.
              <br />
              6.2. O Finant não se responsabiliza por quaisquer danos, diretos ou indiretos, decorrentes do uso ou da impossibilidade de uso
              da plataforma, incluindo perda de dados, receitas ou lucros.
              <br />
              6.3. O usuário é o único responsável pelas decisões financeiras tomadas com base nas informações fornecidas pela plataforma.
            </div>

            <div className={styles.conteudoTopico}>7. Propriedade Intelectual</div>
            <div className={styles.conteudoTexto}>
              7.1. Todos os direitos relativos à plataforma Finant, incluindo design, logotipos, código-fonte, marcas e outros elementos,
              são de propriedade exclusiva da Finant ou de seus licenciantes.
              <br />
              7.2. O usuário não tem permissão para copiar, modificar, distribuir ou reproduzir qualquer parte da plataforma sem autorização
              prévia e por escrito.
            </div>

            <div className={styles.conteudoTopico}>8. Alterações nos Termos</div>
            <div className={styles.conteudoTexto}>
              O Finant reserva-se o direito de modificar este termo de serviço a qualquer momento. Caso isso ocorra, o usuário será
              notificado por meio da plataforma ou e-mail. O uso continuado da plataforma após a notificação implica a aceitação das novas
              condições.
            </div>

            <div className={styles.conteudoTopico}>9. Contato</div>
            <div className={styles.conteudoTexto}>
              Em caso de dúvidas, sugestões ou problemas relacionados à plataforma, o usuário pode entrar em contato pelo e-mail
              suporte@finant.com.br.
            </div>

            <div className={styles.conteudoTopico}>10. Foro e Legislação Aplicável</div>
            <div className={styles.conteudoTexto}>
              Este termo de serviço será regido e interpretado de acordo com as leis brasileiras. Qualquer controvérsia oriunda deste termo
              será submetida ao foro da comarca de residência do usuário.
            </div>
          </div>
        ) : (
          <div className={styles.conteudo}>
            <div className={styles.conteudoTitulo}>Política de Privacidade</div>

            <div className={styles.conteudoTopico}>1. Introdução</div>
            <div className={styles.conteudoTexto}>
              O Finant valoriza a privacidade de seus usuários e está comprometido com a proteção de seus dados pessoais. Esta política de
              privacidade explica como coletamos, utilizamos, armazenamos e protegemos as informações fornecidas pelos usuários ao utilizar
              nossa plataforma.
            </div>

            <div className={styles.conteudoTopico}>2. Dados Coletados</div>
            <div className={styles.conteudoTexto}>
              2.1. <strong>Dados fornecidos pelo usuário:</strong> Durante o cadastro e uso da plataforma, o usuário pode fornecer
              informações como nome, e-mail, senha, dados de contas bancárias e transações financeiras.
              <br />
              2.2. <strong>Dados coletados automaticamente:</strong> Coletamos informações automáticas como endereço IP, tipo de
              dispositivo, sistema operacional, navegador utilizado e dados de uso da plataforma (como páginas acessadas e tempo de
              navegação).
            </div>

            <div className={styles.conteudoTopico}>3. Finalidade do Tratamento de Dados</div>
            <div className={styles.conteudoTexto}>
              3.1. Os dados coletados são utilizados para:
              <ul>
                <li>Fornecer e melhorar os serviços oferecidos pela plataforma;</li>
                <li>Personalizar a experiência do usuário;</li>
                <li>Enviar comunicações importantes, como atualizações de termos e condições;</li>
                <li>Garantir a segurança da plataforma e prevenir atividades fraudulentas;</li>
                <li>Cumprir obrigações legais e regulatórias.</li>
              </ul>
            </div>

            <div className={styles.conteudoTopico}>4. Compartilhamento de Dados</div>
            <div className={styles.conteudoTexto}>
              4.1. O Finant não compartilha dados pessoais dos usuários com terceiros, exceto nas seguintes situações:
              <ul>
                <li>Quando houver consentimento do usuário;</li>
                <li>Para cumprimento de obrigações legais, regulações ou determinações judiciais;</li>
                <li>
                  Para prestação de serviços por parceiros que seguem os mesmos padrões de segurança e confidencialidade adotados pelo
                  Finant;
                </li>
                <li>
                  No caso de operações societárias, como fusões, aquisições ou venda de ativos, desde que o novo controlador respeite esta
                  política de privacidade.
                </li>
              </ul>
            </div>

            <div className={styles.conteudoTopico}>5. Armazenamento e Segurança dos Dados</div>
            <div className={styles.conteudoTexto}>
              5.1. Os dados dos usuários são armazenados em servidores seguros, protegidos por tecnologias de criptografia e acesso
              controlado.
              <br />
              5.2. Adotamos medidas de segurança para proteger os dados contra acessos não autorizados, alterações, divulgações ou
              destruição indevida.
              <br />
              5.3. Embora adotemos medidas de segurança, nenhum sistema é completamente seguro. Assim, não podemos garantir a segurança
              absoluta das informações.
            </div>

            <div className={styles.conteudoTopico}>6. Direitos dos Usuários</div>
            <div className={styles.conteudoTexto}>
              6.1. O usuário tem o direito de:
              <ul>
                <li>Acessar seus dados pessoais armazenados na plataforma;</li>
                <li>Solicitar a correção de dados incorretos, incompletos ou desatualizados;</li>
                <li>Solicitar a exclusão de seus dados pessoais, observadas as obrigações legais de armazenamento;</li>
                <li>Revogar o consentimento para o tratamento de dados, quando aplicável.</li>
              </ul>
              6.2. Para exercer seus direitos, o usuário pode entrar em contato com a equipe de suporte pelo e-mail suporte@finant.com.br.
            </div>

            <div className={styles.conteudoTopico}>7. Retenção de Dados</div>
            <div className={styles.conteudoTexto}>
              Os dados pessoais do usuário serão mantidos enquanto a conta estiver ativa ou conforme necessário para o cumprimento das
              finalidades descritas nesta política. Após a exclusão da conta, poderemos reter alguns dados para cumprir obrigações legais,
              resolver disputas e garantir o cumprimento de nossos termos.
            </div>

            <div className={styles.conteudoTopico}>8. Cookies e Tecnologias Semelhantes</div>
            <div className={styles.conteudoTexto}>
              Utilizamos cookies e tecnologias semelhantes para melhorar a experiência do usuário, analisar o uso da plataforma e
              personalizar conteúdo. O usuário pode gerenciar suas preferências de cookies por meio das configurações do navegador.
            </div>

            <div className={styles.conteudoTopico}>9. Alterações nesta Política</div>
            <div className={styles.conteudoTexto}>
              Esta política de privacidade pode ser atualizada a qualquer momento, e o usuário será notificado sobre mudanças relevantes por
              meio da plataforma ou por e-mail. Recomendamos que o usuário revise periodicamente esta política.
            </div>

            <div className={styles.conteudoTopico}>10. Contato</div>
            <div className={styles.conteudoTexto}>
              Em caso de dúvidas, sugestões ou solicitações relacionadas a esta política de privacidade, o usuário pode entrar em contato
              conosco pelo e-mail suporte@finant.com.br.
            </div>
          </div>
        )}
      </div>
      <Rodape />
    </div>
  );
}

