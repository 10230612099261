/* eslint-disable @typescript-eslint/no-inferrable-types */
export const textoParaValor = (valor: string) => {
  const numero = valor.replace(/\D/g, "");

  return parseFloat(numero) / 100;
};

export const valorParaTexto = (valor: number, obrigatorio: boolean = false) => {
  if (!valor) {
    if (obrigatorio) {
      return Number(0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
    }

    return Number(0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  }

  return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};

export const retornarImagemBanco = (banco?: string) => {
  switch (banco) {
    case "Bradesco":
      return require("../images/bradesco.png");
    case "Nubank":
      return require("../images/nubank.png");
    case "Itaú":
      return require("../images/itau.png");
    case "Santander":
      return require("../images/santander.png");
    case "Banco do Brasil":
      return require("../images/bb.png");
    case "Caixa Econômica":
      return require("../images/caixa.png");
    case "XP Investimentos":
      return require("../images/xp.png");
    default:
      return require("../images/default.jpg");
  }
};

export const retornarImagemBandeira = (bandeira?: string) => {
  switch (bandeira) {
    case "Mastercard":
      return require("../images/mastercard.png");
    case "Visa":
      return require("../images/visa.png");
    case "Elo":
      return require("../images/elo.png");
    case "American Express":
      return require("../images/american.png");
    case "Diners Club":
      return require("../images/diners.png");
    default:
      return require("../images/cartaodefault.png");
  }
};

export const primeiroDiaMesAtual = () => {
  const data = new Date();
  return new Date(data.getFullYear(), data.getMonth(), 1);
};

export const ultimoDiaMesAtual = () => {
  const data = new Date();
  return new Date(data.getFullYear(), data.getMonth() + 1, 0);
};

export const extrairMesAno = (data: Date) => {
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();

  return `${ano}-${mes.toString().padStart(2, "0")}`;
};

