import { useState } from "react";
import Pagina from "../../estruturais/pagina/Pagina";
import styles from "./CartaoCredito.module.css";
import { useCartaoCreditos } from "../../../servicos/cartaoCredito/useCartaoCredito";
import Cadastro from "./Cadastro/Cadastro";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import { retornarImagemBandeira, valorParaTexto } from "../../../utils/Utils";
import Accordion from "../../../componentes/accordion/Accordion";
import Tabela, { Coluna } from "../../../componentes/tabela/Tabela";

const isMobile = window.innerWidth < 768;

const colunas: Coluna[] = [
  { header: "Id", accessor: "id", visible: false },
  { header: "Bandeira", accessor: "icone", type: "image", align: "left", visible: !isMobile },
  { header: "Nome", accessor: "nome", size: "50%" },
  { header: "Melhor data", accessor: "data_fechamento_texto", align: "center", visible: !isMobile },
  { header: "Data de vencimento", accessor: "data_vencimento_texto", align: "center", visible: !isMobile },
  { header: "Saldo", accessor: "saldo", align: "center", type: "currency", size: "10%" },
  { header: "Limite", accessor: "limite", align: "center", type: "currency", size: "10%", visible: !isMobile }
];

export default function CartaoCredito() {
  const { cartoes } = useCartaoCreditos();
  const [selecionada, setSelecionada] = useState<any>(null);

  const totalSaldo = cartoes.reduce((acc, cartao) => acc + parseFloat(cartao.saldo?.toString() || "0"), 0);
  const limiteDisponivel = cartoes.reduce((acc, cartao) => acc + parseFloat(cartao.limite?.toString() || "0"), 0);

  const corSaldo = totalSaldo >= 0 ? "#198000" : "#df0101";

  const handleSelecionar = (row: any) => {
    if (selecionada !== null && selecionada?.id === row?.id) {
      setSelecionada(null);
      return;
    }

    setSelecionada(row);
  };

  const dados = cartoes.map((cartao) => {
    return {
      id: cartao.id,
      limite: cartao.limite,
      nome: cartao.nome,
      icone: retornarImagemBandeira(cartao.bandeira),
      data_fechamento_texto: "Após dia " + cartao.data_fechamento,
      data_vencimento_texto: "Todo dia " + cartao.data_vencimento,
      saldo: cartao.saldo,
      bandeira: cartao.bandeira,
      data_fechamento: cartao.data_fechamento,
      data_vencimento: cartao.data_vencimento
    };
  });

  return (
    <Pagina icone="assured_workload" pagina="Meus Cartões">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Cartões de Crédito</div>
            <Cadastro cartao={selecionada} setCartao={setSelecionada} />
          </div>
          <Informativo>
            <span>Adicione ou edite seus cartões de crédito.</span>
          </Informativo>
          <ResumoSuperior titulo="Total">
            <ResumoDash titulo="Saldo Total" cor={corSaldo}>
              {valorParaTexto(totalSaldo, true)}
            </ResumoDash>
            <ResumoDash titulo="Limite Disponivel" cor={corSaldo}>
              {valorParaTexto(limiteDisponivel, true)}
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.painel}>
            <Tabela selecionada={selecionada} colunas={colunas} dados={dados} onRowClick={handleSelecionar} />
          </div>
        </div>
      </div>
    </Pagina>
  );
}

