import Cabecalho from "./Cabecalho";
import SobreNos from "./SobreNos";
import Funcionalidades from "./Funcionalidades";
import Faq from "./Faq";
import AcaoLogin from "./AcaoLogin";
import Rodape from "./Rodape";
import Aviso from "../estruturais/aviso/Aviso";
import useAvisos from "../../hooks/useAviso";
import styles from "./Landing.module.css";

export default function Landing() {
  const { avisos } = useAvisos();

  return (
    <div>
      <Cabecalho />
      <SobreNos />
      <Funcionalidades />
      <Faq />
      <AcaoLogin />
      <Rodape />
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
    </div>
  );
}

