import { useNavigate } from "react-router-dom";
import styles from "./Quadro.module.css";
import Botao from "../../../componentes/botao/Botao";

type QuadroProps = {
  titulo: string;
  rota?: string;
  children: React.ReactNode;
};

export default function Quadro({ children, titulo, rota }: QuadroProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (rota) {
      navigate(rota);
    }
  };

  const isMobile = window.innerWidth < 768;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cabecalho}>
          <div className={styles.titulo}>{titulo}</div>
          <div className={styles.botao}>
            {rota && <Botao onClick={handleClick} texto={isMobile ? "" : ""} icone="arrow_forward" iconPosition="right" />}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

