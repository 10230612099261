import styles from "./CheckBox.module.css";

export enum CheckBoxSize {
  SMALL = "small",
  MEDIUM = "medium"
}

type CheckBoxProps = {
  label: string;
  checked: boolean;
  icon?: string;
  setChecked: (checked: boolean) => void;
  enabled?: boolean;
  size?: CheckBoxSize;
};

export default function CheckBox({ label, checked, icon, enabled = true, setChecked, size = CheckBoxSize.MEDIUM }: CheckBoxProps) {
  return (
    <div className={styles.container} onClick={() => enabled && setChecked(!checked)} style={{ opacity: enabled ? 1 : 0.5 }}>
      {(icon || label) && (
        <div className={styles.wrapperLabel}>
          {icon && <span className="material-symbols-rounded">{icon}</span>}
          {label && label !== "" && (
            <label className={styles.label} style={{ fontSize: size === CheckBoxSize.SMALL ? 14 : 16 }}>
              {label}
            </label>
          )}
        </div>
      )}
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={checked}
        style={{ width: size === CheckBoxSize.SMALL ? 12 : 16, height: size === CheckBoxSize.SMALL ? 14 : 18 }}
      />
    </div>
  );
}

