import { useNavigate } from "react-router-dom";
import Accordion from "../../componentes/accordion/Accordion";
import Quadro from "../dashboard/Quadro/Quadro";
import Informativo from "../estruturais/informativo/Informativo";
import Pagina from "../estruturais/pagina/Pagina";
import styles from "./Guias.module.css";

export default function Guias() {
  const navigate = useNavigate();

  return (
    <Pagina icone="rocket_launch" pagina="Guias">
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.cabecalho}>
            <div className={styles.titulo}>Guias</div>
          </div>
          <Informativo>
            <span>
              Aprenda a usar o Finant com facilidade. Siga nosso guia passo a passo e comece a organizar suas finanças de forma eficiente.
            </span>
          </Informativo>
          <div className={styles.quadros}>
            <Quadro titulo={"Bem-vindo ao Finant"}>
              <div className={styles.quadro}>
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/Vb7omR82MKM?si=XGncYBoaYLb2RFe0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </Quadro>
            <Quadro titulo={"Duvidas frequentes"}>
              <div className={styles.quadro}>
                <Accordion nome="Guia inicial de primeiros passos" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Guia Inicial de Uso do Finant</h1>
                    <p>
                      Bem-vindo ao Finant! Este guia foi criado para te ajudar a começar de forma simples e rápida. Aqui estão os passos
                      gerais para você configurar e aproveitar ao máximo a plataforma:
                    </p>

                    <div className={styles.step}>
                      <h2>1. Cadastre suas Contas Bancárias</h2>
                      <p>
                        O primeiro passo é adicionar as contas bancárias que você utiliza. Isso permitirá que você controle o saldo e
                        acompanhe todas as suas movimentações financeiras.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>2. Adicione seus Cartões de Crédito</h2>
                      <p>
                        Se você utiliza cartões de crédito, cadastre-os na plataforma. Assim, você poderá gerenciar suas faturas, registrar
                        gastos e até mesmo lançar despesas parceladas.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>3. Configure suas Categorias</h2>
                      <p>
                        Organize suas despesas e receitas criando categorias personalizadas. Isso ajudará você a entender melhor para onde
                        seu dinheiro está indo e planejar seus gastos.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>4. Registre suas Movimentações</h2>
                      <p>
                        Comece a registrar suas despesas, receitas e transferências. Cada movimentação será associada a uma conta, categoria
                        ou cartão de crédito, facilitando o controle financeiro.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>5. Monitore seu Resumo Financeiro</h2>
                      <p>
                        Utilize a Dashboard de Contas para ter uma visão geral das suas finanças, como o saldo total, as categorias com
                        maior impacto e as fontes das suas despesas e receitas.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>6. Acompanhe sua Fatura do Cartão de Crédito</h2>
                      <p>
                        Caso utilize cartões de crédito, a Dashboard de Cartão de Crédito te ajudará a acompanhar os gastos do mês, analisar
                        as categorias que mais consomem sua fatura e revisar os lançamentos recentes.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>7. Planeje suas Finanças</h2>
                      <p>
                        Use a funcionalidade de Metas Financeiras para criar objetivos de gastos e economias. Isso ajudará você a manter o
                        controle e a poupar para o futuro.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Atualize suas movimentações regularmente para manter seu controle financeiro em dia e aproveite os gráficos e
                        relatórios para tomar decisões mais assertivas.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para adicionar uma nova conta?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Adicionar uma Nova Conta</h1>
                    <p>
                      Adicionar uma conta no Finant é simples e rápido. Siga este guia para configurar suas contas bancárias e organizar
                      suas finanças:
                    </p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Contas</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Contas"</strong>.
                        </li>
                        <li>
                          Na tela de resumo de contas, clique no botão <strong>"Adicionar conta"</strong>.
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os dados da sua conta</h2>
                      <ul>
                        <li>
                          <strong>Saldo inicial:</strong> Insira o saldo atual da sua conta bancária.
                        </li>
                        <li>
                          <strong>Instituição:</strong> Escolha o banco ou instituição financeira na lista suspensa.
                        </li>
                        <li>
                          <strong>Descrição:</strong> Adicione uma descrição para identificar facilmente esta conta (ex.: Conta Corrente,
                          Poupança, etc.).
                        </li>
                        <li>
                          <strong>Tipo de Conta:</strong> Selecione o tipo de conta (ex.: Corrente, Poupança, Investimentos).
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Escolha uma cor para identificar sua conta</h2>
                      <p>
                        Clique em uma das opções de cor disponíveis. A cor selecionada ajudará você a diferenciar esta conta nas listas e
                        gráficos.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 4: Salve suas informações</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong> para concluir o processo. Sua nova conta será adicionada à lista de contas
                        e estará disponível para registro de movimentações financeiras.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>Use descrições claras e cores distintas para facilitar a identificação das contas no dia a dia.</p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para adicionar um novo cartão de crédito?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Adicionar um Cartão de Crédito no Finant</h1>
                    <p>Adicionar um cartão de crédito no Finant é simples e prático. Siga este guia para começar:</p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Cartões de Crédito</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Cartões de Crédito"</strong>.
                        </li>
                        <li>
                          Na tela de resumo dos cartões, clique no botão <strong>"Adicionar cartão"</strong>.
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os dados do cartão</h2>
                      <ul>
                        <li>
                          <strong>Bandeira:</strong> Selecione a bandeira do seu cartão na lista suspensa (ex.: Visa, Mastercard, etc.).
                        </li>
                        <li>
                          <strong>Nome:</strong> Insira um nome para identificar o cartão (ex.: Cartão Banco X).
                        </li>
                        <li>
                          <strong>Limite:</strong> Informe o limite total do cartão.
                        </li>
                        <li>
                          <strong>Dia do fechamento:</strong> Insira o dia do mês em que sua fatura fecha.
                        </li>
                        <li>
                          <strong>Dia do vencimento:</strong> Insira o dia do mês em que a fatura deve ser paga.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Salve as informações do cartão</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong> para concluir o processo. Seu cartão será adicionado à lista e estará
                        disponível para gerenciar seus gastos e transações.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Adicione nomes claros e insira corretamente as datas de fechamento e vencimento para facilitar o controle de seus
                        cartões no dia a dia.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para adicionar uma nova categoria?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Adicionar uma Nova Categoria</h1>
                    <p>
                      Adicionar uma categoria no Finant é fácil e rápido. Siga este guia para criar categorias personalizadas e organizar
                      melhor suas despesas e receitas:
                    </p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Categorias</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Categorias"</strong>.
                        </li>
                        <li>
                          Na tela de resumo de categorias, clique no botão <strong>"Adicionar categoria"</strong>.
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os detalhes da sua categoria</h2>
                      <ul>
                        <li>
                          <strong>Tipo:</strong> Escolha entre "Despesa" ou "Receita", dependendo do tipo de movimentação que essa categoria
                          representará.
                        </li>
                        <li>
                          <strong>Nome:</strong> Insira o nome da categoria (ex.: Alimentação, Transporte, Salário, etc.).
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Escolha uma cor e um ícone para a categoria</h2>
                      <p>
                        Clique em uma das opções de cor disponíveis para identificar visualmente a categoria. Em seguida, escolha um ícone
                        que melhor represente essa categoria.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 4: Salve suas informações</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong> para concluir o processo. Sua nova categoria será adicionada à lista e
                        estará disponível para ser utilizada em movimentações financeiras.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>Crie categorias claras e bem definidas para facilitar o controle das suas finanças no dia a dia.</p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para adicionar uma nova despesa ou receita?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Adicionar Despesas e Receitas no Finant</h1>
                    <p>
                      Adicionar despesas e receitas no Finant é rápido e prático. Siga este guia para organizar suas movimentações
                      financeiras:
                    </p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Movimentações</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Movimentações"</strong> ou <strong>"Novo"</strong>.
                        </li>
                        <li>
                          Na tela de resumo financeiro, escolha entre:
                          <ul>
                            <li>
                              <strong>Adicionar despesa:</strong> Clique no botão laranja.
                            </li>
                            <li>
                              <strong>Adicionar receita:</strong> Clique no botão verde.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os detalhes da sua despesa ou receita</h2>
                      <ul>
                        <li>
                          <strong>Data:</strong> Insira a data da movimentação.
                        </li>
                        <li>
                          <strong>Descrição:</strong> Adicione um nome ou descrição breve para identificar a transação.
                        </li>
                        <li>
                          <strong>Valor:</strong> Informe o valor da despesa ou receita.
                        </li>
                        <li>
                          <strong>Forma de pagamento:</strong> Selecione a forma de pagamento (ex.: Dinheiro, Cartão, Transferência).
                        </li>
                        <li>
                          <strong>Conta:</strong> Escolha a conta associada à transação.
                        </li>
                        <li>
                          <strong>Categoria:</strong> Defina a categoria para organizar a movimentação (ex.: Alimentação, Salário, etc.).
                          <br />
                          Caso precise criar uma nova conta ou categoria, clique no botão “+” ao lado do campo correspondente.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Marque se for uma despesa fixa (opcional)</h2>
                      <p>
                        Se a despesa for recorrente (ex.: aluguel, assinatura), marque a opção <strong>“Despesa fixa?”</strong>.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 4: Salve a movimentação</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong> para concluir o processo. Sua despesa ou receita será adicionada à lista e
                        aparecerá no resumo financeiro.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>Mantenha suas movimentações atualizadas para ter um controle financeiro mais eficiente.</p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para lançar um parcelamento no Finant?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Lançar Parcelamentos no Finant</h1>
                    <p>
                      Adicionar um lançamento parcelado no Finant é simples. Siga este guia para registrar suas despesas no cartão de
                      crédito com parcelamento:
                    </p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Movimentações</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Movimentações"</strong> ou <strong>"Novo"</strong>.
                        </li>
                        <li>
                          Clique no botão laranja <strong>"Adicionar despesa"</strong>.
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os detalhes da despesa</h2>
                      <ul>
                        <li>
                          <strong>Data:</strong> Insira a data da primeira parcela.
                        </li>
                        <li>
                          <strong>Descrição:</strong> Adicione uma descrição para identificar a despesa.
                        </li>
                        <li>
                          <strong>Valor:</strong> Insira o valor total da despesa que será parcelada.
                        </li>
                        <li>
                          <strong>Forma de pagamento:</strong> Escolha <strong>"Cartão de Crédito"</strong>.
                        </li>
                        <li>
                          <strong>Cartão de Crédito:</strong> Selecione o cartão utilizado para a despesa.
                        </li>
                        <li>
                          <strong>Categoria:</strong> Defina a categoria da despesa (ex.: Compras, Educação, etc.).
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Habilite o parcelamento</h2>
                      <p>
                        Marque a opção <strong>"Este lançamento será parcelado?"</strong>. Após marcar, um campo adicional será exibido para
                        que você informe:
                      </p>
                      <ul>
                        <li>
                          <strong>Quantidade de parcelas:</strong> Insira o número total de parcelas.
                        </li>
                        <li>
                          O Finant calculará automaticamente o valor de cada parcela e registrará o lançamento de acordo com as datas de
                          vencimento.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 4: Salve o parcelamento</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong>. As parcelas serão registradas automaticamente e estarão visíveis na lista
                        de movimentações, associadas ao cartão de crédito selecionado.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Use descrições claras para identificar facilmente as parcelas e mantenha seu controle financeiro atualizado para
                        evitar surpresas no final do mês.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como faço para realizar uma transferência entre contas?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Realizar uma Transferência Entre Contas no Finant</h1>
                    <p>
                      Realizar uma transferência entre contas no Finant é fácil e rápido. Siga este guia para registrar suas transferências
                      de forma organizada:
                    </p>

                    <div className={styles.step}>
                      <h2>Passo 1: Acesse a área de Movimentações</h2>
                      <ol>
                        <li>
                          No menu lateral esquerdo, clique em <strong>"Movimentações"</strong> ou <strong>"Novo"</strong>.
                        </li>
                        <li>
                          Clique no botão roxo <strong>"Transferência entre contas"</strong>.
                        </li>
                      </ol>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 2: Preencha os detalhes da transferência</h2>
                      <ul>
                        <li>
                          <strong>Valor:</strong> Insira o valor da transferência.
                        </li>
                        <li>
                          <strong>Data:</strong> Selecione a data em que a transferência foi realizada.
                        </li>
                        <li>
                          <strong>Descrição:</strong> Adicione uma breve descrição para identificar a transferência.
                        </li>
                        <li>
                          <strong>Conta de Origem:</strong> Escolha a conta da qual o valor será debitado.
                        </li>
                        <li>
                          <strong>Conta de Destino:</strong> Escolha a conta para a qual o valor será transferido.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Passo 3: Salve a transferência</h2>
                      <p>
                        Clique no botão <strong>"Salvar"</strong> para concluir o processo. A transferência será registrada e aparecerá no
                        resumo de movimentações de ambas as contas.
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Use descrições claras para identificar facilmente as transferências no histórico de movimentações e mantenha suas
                        contas sempre atualizadas.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como funciona a Dashboard de Contas?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Funciona a Dashboard de Contas</h1>
                    <p>
                      A Dashboard de Contas oferece um resumo detalhado das suas movimentações financeiras, permitindo que você visualize
                      seus gastos, receitas e fontes de despesas de forma clara e organizada.
                    </p>

                    <div className={styles.step}>
                      <h2>Resumo das Contas</h2>
                      <p>Nesta seção, você encontrará um resumo mensal que inclui os seguintes dados:</p>
                      <ul>
                        <li>
                          <strong>Despesas:</strong> Exibe um gráfico de pizza detalhando as categorias de gastos do mês atual, como
                          Transporte, Contas Básicas, etc.
                        </li>
                        <li>
                          <strong>Receitas:</strong> Exibe as fontes de entrada financeira, caso existam, organizadas por categoria.
                        </li>
                        <li>
                          <strong>Fontes das Despesas:</strong> Mostra como suas despesas estão sendo pagas (ex.: Cartão de Crédito, Boleto,
                          etc.).
                        </li>
                        <li>
                          <strong>Fontes das Receitas:</strong> Quando houver entradas financeiras, você verá as origens, como salários ou
                          outras categorias configuradas.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>O que você pode analisar com este resumo?</h2>
                      <p>Com o resumo das contas, você pode responder a perguntas como:</p>
                      <ul>
                        <li>Quais categorias representam a maior parte dos meus gastos?</li>
                        <li>Qual é o meu saldo total mensal?</li>
                        <li>Quais são as principais fontes das minhas despesas?</li>
                        <li>Estou gastando mais do que estou recebendo?</li>
                        <li>Em quais categorias posso economizar para melhorar minha saúde financeira?</li>
                      </ul>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Use os gráficos interativos para identificar rapidamente padrões nos seus gastos e receitas. Isso ajuda a tomar
                        decisões mais assertivas no controle financeiro.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como funciona a Dashboard de Cartão de Crédito?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Como Funciona a Dashboard de Cartão de Crédito</h1>
                    <p>
                      A Dashboard de Cartão de Crédito oferece uma visão detalhada dos gastos realizados no mês atual, permitindo que você
                      acompanhe sua fatura, identifique categorias de gastos e analise os últimos lançamentos.
                    </p>

                    <div className={styles.step}>
                      <h2>Fatura do Cartão</h2>
                      <p>
                        No topo, você verá o <strong>total da fatura</strong> do mês atual, destacando o saldo devido. Utilize as setas ao
                        lado para navegar entre os meses e visualizar os dados de faturas passadas.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>Gráfico de Categorias</h2>
                      <p>
                        O gráfico de pizza exibe a distribuição de gastos por categoria, ajudando a identificar onde você mais utiliza o
                        cartão. Algumas informações que você pode analisar:
                      </p>
                      <ul>
                        <li>
                          <strong>Categoria com maior gasto:</strong> Identifique qual categoria está consumindo mais do seu limite.
                        </li>
                        <li>
                          <strong>Distribuição percentual:</strong> Veja a porcentagem que cada categoria representa em relação ao total
                          gasto.
                        </li>
                      </ul>
                    </div>

                    <div className={styles.step}>
                      <h2>Últimos Lançamentos</h2>
                      <p>
                        À direita, você verá os últimos lançamentos realizados no cartão de crédito, organizados por data, descrição e
                        valor. Isso ajuda a rastrear facilmente as despesas recentes.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>O que você pode analisar com essa dashboard?</h2>
                      <p>Com essa visão detalhada, você pode responder a perguntas como:</p>
                      <ul>
                        <li>Estou gastando mais do que deveria em alguma categoria específica?</li>
                        <li>Qual o valor total de despesas do cartão neste mês?</li>
                        <li>Quais foram os lançamentos mais recentes no meu cartão?</li>
                        <li>Estou utilizando meu cartão de forma sustentável?</li>
                      </ul>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Use as informações dessa seção para ajustar seus hábitos de consumo, evitando gastos desnecessários e organizando
                        melhor sua fatura.
                      </p>
                    </div>
                  </div>
                </Accordion>
                <Accordion nome="Como entrar em contato com o Suporte do Finant?" expandidoDefault={false} habilitado={true}>
                  <div className={styles.guide}>
                    <h1>Entre em Contato com o Suporte do Finant</h1>
                    <p>
                      Estamos aqui para te ajudar! Caso tenha dúvidas, sugestões ou reclamações, entre em contato com nossa equipe de
                      suporte. Seu feedback é muito importante para nós!
                    </p>

                    <div className={styles.step}>
                      <h2>1. Contato com o Suporte</h2>
                      <p>
                        Para entrar em contato com o suporte, envie um e-mail para: <strong>contato@finant.com.br</strong>. Nossa equipe
                        responderá o mais rápido possível.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>2. Sugestões e Reclamações</h2>
                      <p>
                        Quer sugerir melhorias ou relatar algum problema? Estamos sempre abertos a ouvir você. Envie suas sugestões ou
                        reclamações para o mesmo e-mail: <strong>contato@finant.com.br</strong>.
                      </p>
                    </div>

                    <div className={styles.step}>
                      <h2>3. Segurança dos Dados</h2>
                      <p>
                        Sua segurança é nossa prioridade. Não armazenamos dados sensíveis, como senhas ou informações de pagamento. Além
                        disso, utilizamos tecnologias de ponta para garantir a proteção das suas informações gerais.
                      </p>
                      <p>
                        Para saber mais sobre como protegemos seus dados, leia nossos
                        <span className={styles.link} onClick={() => navigate("/termos-e-politicas")}>
                          Termos de Serviço
                        </span>{" "}
                        e nossa
                        <span className={styles.link} onClick={() => navigate("/termos-e-politicas")}>
                          Política de Privacidade
                        </span>
                        .
                      </p>
                    </div>

                    <div className={styles.tip}>
                      <h2>Dica:</h2>
                      <p>
                        Antes de enviar uma solicitação ao suporte, verifique nossos guias e perguntas frequentes. Talvez sua dúvida já
                        tenha sido respondida!
                      </p>
                    </div>
                  </div>
                </Accordion>
              </div>
            </Quadro>
          </div>
        </div>
      </div>
    </Pagina>
  );
}

