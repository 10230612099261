import { useEffect, useState } from "react";
import styles from "./Cadastro.module.css";
import { Meta } from "../../../../servicos/metas/typeMeta";
import Formulario from "./Formulario";

type CadastroProps = {
  meta: Meta | null;
  setMeta: (meta: Meta | null) => void;
};

export default function Cadastro({ meta, setMeta }: CadastroProps) {
  const [modal, setModal] = useState(false);

  const fecharModal = () => {
    setModal(false);
    setMeta(null);
  };

  useEffect(() => {
    if (meta) {
      setModal(true);
    }
  }, [meta]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAcao} onClick={() => setModal(true)} style={{ background: "#007bff", borderColor: "#007bff" }}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Adicionar Meta</span>
        </div>
      </div>
      {modal && <Formulario meta={meta} fecharModal={fecharModal} />}
    </div>
  );
}

