import styles from "./Login.module.css";
import Logotipo from "../estruturais/menuLateral/logotipo/Logotipo";
import useAvisos from "../../hooks/useAviso";
import Aviso from "../estruturais/aviso/Aviso";
import FormularioLogin from "./formularioLogin/FormularioLogin";

export default function Login() {
  const { avisos } = useAvisos();
  return (
    <div className={styles.container}>
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Acesse sua conta</div>
          <FormularioLogin />
        </div>
      </div>
    </div>
  );
}

