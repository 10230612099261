import styles from "./MinhasTransacoes.module.css";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Transacao } from "../../../../servicos/transacoes/typeTransacao";
import useCategoriasUtils from "../../../../hooks/useCategoriasUtils";
import Quadro from "../../Quadro/Quadro";
import Carregando from "../../Carregando/Carregando";

Chart.register(ArcElement, Tooltip, Legend);

type DashboardProps = {
  tipo: "R" | "D" | "C";
  loading: boolean;
  transacoes: Transacao[];
};

export default function MinhasTransacoes({ tipo, loading, transacoes }: DashboardProps) {
  const { retornarCor, retornarDescricao } = useCategoriasUtils();

  const transacoesPorCategoria = transacoes.reduce((acc: any, transacao: Transacao) => {
    const categoriaId = transacao.categoria_id || 0;
    const valor = parseFloat(transacao.valor.toString());

    if (isNaN(valor)) {
      console.error(`Valor inválido em transação: ${transacao}`);
      return acc;
    }

    acc[categoriaId] = acc[categoriaId] ? acc[categoriaId] + valor : valor;
    return acc;
  }, {});

  const valores = Object.values(transacoesPorCategoria) as number[];

  // Calcula o total das transações
  const totalTransacoes = valores.reduce((sum, valor) => sum + valor, 0);

  const categoriaOrdenadas = Object.entries(transacoesPorCategoria)
    .map(([categoriaId, valor]) => {
      const descricao = retornarDescricao(Number(categoriaId)) || "Outra";
      const percentual = ((Number(valor) / totalTransacoes) * 100).toFixed(2); // Calcula o percentual
      return { categoriaId, descricao, valor: Number(valor), percentual };
    })
    .sort((a, b) => b.valor - a.valor);

  // Gerando as cores e descrições das categorias usando o hook
  const cores = categoriaOrdenadas.map((categoria) => retornarCor(Number(categoria.categoriaId)));
  const descricoes = categoriaOrdenadas.map((categoria) => {
    return `${categoria.descricao} - ${categoria.percentual}%`; // Formata a descrição como "nome_da_categoria - XX%"
  });

  const data = {
    labels: descricoes, // Descrições no formato "nome_da_categoria - XX%"
    datasets: [
      {
        data: categoriaOrdenadas.map((categoria) => categoria.valor), // Valores ordenados
        backgroundColor: cores,
        hoverBackgroundColor: cores,
        cutout: "60%" // Donut
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false // Remove as legendas
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const categoria = categoriaOrdenadas[tooltipItem.dataIndex]; // Use a lista ordenada para acessar o valor correto
            const formattedValue = categoria.valor.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            });
            return `Total: ${formattedValue}`;
          }
        }
      },
      datalabels: {
        display: false // Esconde os datalabels
      }
    }
  };

  const possuiDados = categoriaOrdenadas.length > 0 && !loading;

  return (
    <div className={styles.container} style={{ gridColumn: tipo === "C" ? "span 4" : "span 3" }}>
      <Quadro titulo={tipo === "D" ? "Despesas" : tipo === "R" ? "Receitas" : "Cartões de Crédito"} rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            <div className={styles.grafico}>
              <Pie data={data} options={options} />
            </div>
            <div className={styles.lista}>
              {categoriaOrdenadas.map((categoria, index) => {
                return (
                  <div key={categoria.categoriaId} className={styles.item}>
                    <div className={styles.categoria}>
                      <div className={styles.categoriaCor} style={{ backgroundColor: cores[index] }}></div>
                      <span>{categoria.descricao}</span>
                    </div>
                    <div className={styles.valor}>
                      {categoria.valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </div>
                    <div className={styles.percentual}>{categoria.percentual}%</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Carregando loading={loading} />
        )}
      </Quadro>
    </div>
  );
}

