import { useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./CriarConta.module.css";
import Botao from "../../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../componentes/checkbox/CheckBox";
import Logotipo from "../../estruturais/menuLateral/logotipo/Logotipo";
import { usePerfis } from "../../../servicos/perfis/usePerfil";
import useAvisos from "../../../hooks/useAviso";
import Aviso from "../../estruturais/aviso/Aviso";
import useFirebase from "../../../hooks/useFirebase";
import Google from "../../../images/google.svg";

export default function CriarConta() {
  const [dados, setDados] = useState({ email: "", senha: "", senhaRepetida: "", termoAceite: true });

  const { loading, loginOrRegisterWithGoogle, register } = useFirebase();
  const { avisos, adicionarAviso } = useAvisos();
  const { login, createPerfil } = usePerfis(false);

  const navigate = useNavigate();

  const handleCriarConta = async () => {
    if (dados.email === "") {
      adicionarAviso("Informe o e-mail", "alerta");
      return;
    }

    if (dados.senha === "") {
      adicionarAviso("Informe a senha", "alerta");
      return;
    }

    if (dados.senhaRepetida === "") {
      adicionarAviso("Informe a confirmação da senha", "alerta");
      return;
    }

    if (dados.senha !== dados.senhaRepetida) {
      adicionarAviso("As senhas não conferem", "alerta");
      return;
    }

    const user = await register(dados.email, dados.senha);

    if (user) {
      await createPerfil({ nome: user.displayName ?? "", telefone: user.phoneNumber ?? "", email: dados.email, hashCode: user.uid }, true);
    }
  };

  const handleLoginGoogle = async () => {
    const user = await loginOrRegisterWithGoogle();

    if (user) {
      login(user.email ?? "", user.uid, false);
    }
  };

  const todosPreenchidos = !!(dados.email && dados.senha && dados.senhaRepetida);

  return (
    <div className={styles.container}>
      <div className={styles.avisos}>
        {avisos.map((aviso) => (
          <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <Logotipo />
          <div className={styles.voltar} onClick={() => navigate("/login")}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Cria sua conta</div>
          <div className={styles.formulario}>
            <TextField
              label="Digite seu e-mail"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
              autocomplete="off"
            />
            <TextField
              label="Digite sua senha"
              value={dados.senha}
              onChange={(senha) => setDados({ ...dados, senha: senha })}
              type="password"
              enabled={true}
              autocomplete="new-password"
            />
            <TextField
              label="Digite sua senha novamente"
              value={dados.senhaRepetida}
              onChange={(senhaRepetida) => setDados({ ...dados, senhaRepetida: senhaRepetida })}
              type="password"
              enabled={true}
              autocomplete="new-password"
            />
            <Botao
              texto={!loading ? "Continuar" : ""}
              icone={!loading ? "check" : "progress_activity"}
              onClick={() => handleCriarConta()}
              background="#ffd862"
              color="#333333"
              enabled={todosPreenchidos}
              spin={loading}
            />
            <div className={styles.provedores}>
              <span>ou</span>
              <div className={styles.provedor} onClick={() => handleLoginGoogle()}>
                <img src={Google} alt="Google" />
                <span>Criar com o Google</span>
              </div>
            </div>
            <div className={styles.termos}>
              <span className={styles.textoTermo}>
                Ao criar a conta, você concorda com os <b onClick={() => navigate("/termos-e-politicas")}>Termos de Serviço</b> e a{" "}
                <b onClick={() => navigate("/termos-e-politicas")}>Política de Privacidade</b> do Finant.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

