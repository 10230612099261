import { useEffect, useState } from "react";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { valorParaTexto } from "../../../utils/Utils";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import styles from "./ResumoCartoes.module.css";
import { useCartaoCreditos } from "../../../servicos/cartaoCredito/useCartaoCredito";
import MinhasTransacoes from "../ResumoContas/MinhasTransacoes/MinhasTransacoes";
import UltimasTransacoes from "../ResumoContas/UltimasTransacoes/UltimasTransacoes";
import TransacoesMensais from "../ResumoContas/TransacoesMensais/TransacoesMensais";

const isMobile = window.innerWidth < 768;

const retornaDataPadrao = (diaFechamento: number) => {
  const dataAtual = new Date();
  const proximaData = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 1);

  if (dataAtual.getDate() > diaFechamento) {
    return proximaData;
  }

  return dataAtual;
};

export default function ResumoCartoes() {
  const { cartoes } = useCartaoCreditos();
  const { loading, transacoes, fetchTransacoes } = useTransacoes();
  const [dataSelecionada, setDataSelecionada] = useState(retornaDataPadrao(cartoes[0]?.data_fechamento || new Date().getDate()));
  const [cartaoSelecionado, setCartaoSelecionado] = useState(cartoes[0]);

  const mesFormatado = dataSelecionada.toLocaleString("pt-BR", { month: "long", year: "numeric" }).replace(" de ", " ");

  const handleMesAnterior = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() - 1);
    setDataSelecionada(novoMes);
  };

  const handleProximoMes = () => {
    const novoMes = new Date(dataSelecionada);
    novoMes.setMonth(novoMes.getMonth() + 1);
    setDataSelecionada(novoMes);
  };

  const handleCartaoAnterior = () => {
    const index = cartoes.indexOf(cartaoSelecionado);

    if (index === 0) {
      return;
    }

    const novoIndex = index === 0 ? cartoes.length - 1 : index - 1;
    setCartaoSelecionado(cartoes[novoIndex]);
  };

  const handleProximoCartao = () => {
    const index = cartoes.indexOf(cartaoSelecionado);

    if (index === cartoes.length - 1) {
      return;
    }

    const novoIndex = index === cartoes.length - 1 ? 0 : index + 1;
    setCartaoSelecionado(cartoes[novoIndex]);
  };

  //calcula o total de todas as transações, receitas - despesas
  const totalReceitas = transacoes
    .filter((transacao) => transacao.tipo === "R")
    .reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);

  const totalDespesas =
    transacoes.filter((transacao) => transacao.tipo === "D").reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0) *
    -1;

  const totalTransacoes = totalReceitas + totalDespesas;
  const corTransacao = totalTransacoes > 0 ? "#198000" : "#df0101";

  useEffect(() => {
    if (!cartaoSelecionado?.id) {
      return;
    }

    const dataInicio = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth() - 2, cartaoSelecionado.data_fechamento));
    const dataFim = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth() - 1, cartaoSelecionado.data_fechamento));

    fetchTransacoes({ cartao: cartaoSelecionado.id.toString(), dataInicial: dataInicio, dataFinal: dataFim });
  }, [cartaoSelecionado, dataSelecionada]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cabecalho} style={{ justifyContent: isMobile ? "center" : "space-between" }}>
          {isMobile ? (
            <div />
          ) : (
            <Informativo>
              <span>
                Selecione a fatura do cartão de crédito para visualizar o resumo do mês. Acompanhe o valor total da fatura, gastos e
                receitas.
              </span>
            </Informativo>
          )}
          <div className={styles.filtros}>
            <div className={styles.meses}>
              {cartoes.indexOf(cartaoSelecionado) > 0 && (
                <div className={styles.botaoAcao} onClick={handleCartaoAnterior}>
                  <span className="material-symbols-rounded">chevron_left</span>
                </div>
              )}
              <span className={styles.textoAcao}>{cartaoSelecionado?.nome ?? "-"}</span>
              {cartoes.indexOf(cartaoSelecionado) < cartoes.length - 1 && (
                <div className={styles.botaoAcao} onClick={handleProximoCartao}>
                  <span className="material-symbols-rounded">chevron_right</span>
                </div>
              )}
            </div>
            <div className={styles.meses}>
              <div className={styles.botaoAcao} onClick={handleMesAnterior}>
                <span className="material-symbols-rounded">chevron_left</span>
              </div>
              <span className={styles.textoAcao}>{mesFormatado.charAt(0).toUpperCase() + mesFormatado.slice(1)}</span>
              <div className={styles.botaoAcao} onClick={handleProximoMes}>
                <span className="material-symbols-rounded">chevron_right</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.painel}>
          <ResumoSuperior titulo="Total da fatura">
            <ResumoDash titulo="" cor={corTransacao}>
              <div style={{ fontSize: isMobile ? "16px" : "16px", fontWeight: "bold" }}>{valorParaTexto(totalTransacoes, true)}</div>
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.graficos}>
            <MinhasTransacoes tipo="C" loading={loading} transacoes={transacoes} />
            <UltimasTransacoes loading={loading} transacoes={transacoes} />
          </div>
          {/* <div className={styles.graficos}>
            <TransacoesMensais transacoes={transacoes} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

