import { useEffect, useState } from "react";
import axios from "axios";
import { Meta } from "./typeMeta";
import { useMetaContexto } from "../../contextos/MetaContexto";
import useAvisos from "../../hooks/useAviso";

const API_URL = process.env.REACT_APP_API_URL + "/metas";

export function useMetas() {
  const { metas, setMetas } = useMetaContexto();

  const { adicionarAviso } = useAvisos();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem("token");

  // Fetch all metas with JWT token and optional filters
  const fetchMetas = async (mesAno: string) => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      // Enviar o filtro `mesAno` como parâmetro
      const response = await axios.get<Meta[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { mesAno } // Adiciona o filtro `mesAno`
      });

      setMetas(response.data);
    } catch (err) {
      setError("Erro ao buscar as metas.");
    } finally {
      setLoading(false);
    }
  };

  // Create a new meta with JWT token
  const createMeta = async (newMeta: Meta) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      await axios.post(API_URL, newMeta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      adicionarAviso("Meta criada com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao criar a meta.");
    } finally {
      setLoading(false);
    }
  };

  // Update a meta with JWT token
  const updateMeta = async (id: number, updatedMeta: Meta) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      await axios.put(`${API_URL}/${id}`, updatedMeta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      adicionarAviso("Meta alterada com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao atualizar a meta.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a meta with JWT token
  const deleteMeta = async (id: number) => {
    const token = getToken();
    if (!token) {
      setError("Usuário não autenticado.");
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setMetas((prevMetas) => prevMetas.filter((meta) => meta.id !== id));

      adicionarAviso("Meta removida com sucesso!", "sucesso");
    } catch (err) {
      setError("Erro ao deletar a movimentação.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      adicionarAviso(error, "erro");
      setError(null);
    }
  }, [error]);

  return {
    metas,
    loading,
    fetchMetas,
    createMeta,
    updateMeta,
    deleteMeta
  };
}

