import React from "react";
import styles from "./Faq.module.css";
import Accordion from "../../componentes/accordion/Accordion";

const questions = [
  {
    pergunta: "O Finant é gratuito?",
    resposta: "Sim, a grande maioria dos recursos do Finant são gratuitos."
  },
  {
    pergunta: "Quais são os métodos de segurança do Finant?",
    resposta: "O Finant utiliza criptografia de ponta para proteger seus dados."
  },
  {
    pergunta: "Os dados são armazenados com segurança?",
    resposta: "Sim, os dados são armazenados com segurança, utilizamos servidores do Google e Amazon para garantir a segurança dos dados."
  },
  {
    pergunta: "Posso controlar meus cartões de crédito?",
    resposta:
      "Sim, é possível adicionar cartões de crédito e controlar seus gastos, assim como acompanhar suas movimentações com dashboards intuitivas"
  },

  {
    pergunta: "Posso acessar em qualquer dispositivo?",
    resposta:
      "Sim, o Finant é compativel com todos os dispositivos e pode ser acessado em qualquer dispositivo movel, tablet ou computador."
  },
  {
    pergunta: "Como criar metas financeiras?",
    resposta:
      "É possível criar metas financeiras personalizadas para categorias como alimentação, lazer e transporte, e acompanhar seu progresso."
  },
  {
    pergunta: "É possível personalizar categorias?",
    resposta: "Sim, é possível criar e personalizar categorias, garantindo assim uma melhor organização financeira personalizada por você."
  }
];

function Faq() {
  return (
    <section className={styles.section}>
      <h2>Tem alguma dúvida?</h2>
      <div className={styles.accordions}>
        {questions.map((question, index) => (
          <Accordion key={index} nome={question.pergunta} habilitado={true} expandidoDefault={false}>
            <div className={styles.resposta}>{question.resposta}</div>
          </Accordion>
        ))}
      </div>
    </section>
  );
}

export default Faq;

