import { useEffect, useState } from "react";
import styles from "./Cadastro.module.css";
import { Conta } from "../../../../servicos/contas/typeConta";
import Formulario from "./Formulario";

type CadastroProps = {
  conta: Conta | null;
  setConta: (conta: Conta | null) => void;
};

export default function Cadastro({ conta, setConta }: CadastroProps) {
  const [modal, setModal] = useState(false);

  const fecharModal = () => {
    setModal(false);
    setConta(null);
  };

  useEffect(() => {
    if (conta) {
      setModal(true);
    }
  }, [conta]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.botaoAcao} onClick={() => setModal(true)} style={{ background: "#007bff", borderColor: "#007bff" }}>
          <span className="material-symbols-rounded">add</span>
          <span className={styles.textoAcao}>Adicionar Conta</span>
        </div>
      </div>
      {modal && <Formulario conta={conta} fecharModal={fecharModal} />}
    </div>
  );
}

