import { useEffect, useState } from "react";
import styles from "./Formulario.module.css";
import Botao from "../../../../componentes/botao/Botao";
import TextField from "../../../../componentes/textField/TextField";
import DropDown from "../../../../componentes/dropdown/DropDown";
import Slider from "../../../../componentes/slider/Slider";
import { Meta } from "../../../../servicos/metas/typeMeta";
import { useMetas } from "../../../../servicos/metas/useMetas";
import { valorParaTexto, textoParaValor } from "../../../../utils/Utils";
import { useCategorias } from "../../../../servicos/categorias/useCategorias";
import Alerta, { OpcoesAlerta } from "../../../../componentes/alerta/Alerta";

const meses = Array.from({ length: 12 }, (_, i) => {
  const mes = (i + 1).toString().padStart(2, "0");
  return { valor: `${new Date().getFullYear()}-${mes}`, texto: `${mes}/${new Date().getFullYear()}` };
});

type FormularioProps = {
  meta: Meta | null;
  fecharModal: () => void;
};

export default function Formulario({ meta, fecharModal }: FormularioProps) {
  const [dados, setDados] = useState({ categoria_id: "", mesAno: "", valor: "" });
  const { loading, createMeta, updateMeta, deleteMeta } = useMetas();
  const [mostrarAvisoExclusao, setMostrarAvisoExclusao] = useState(false);
  const { categorias } = useCategorias();

  const todosPreenchidos = !!(dados.categoria_id && dados.mesAno && dados.valor);

  const handleSalvar = async () => {
    if (!todosPreenchidos) return;

    const metaAtualizada = {
      categoria_id: parseInt(dados.categoria_id, 10),
      mesAno: dados.mesAno,
      valor: textoParaValor(dados.valor)
    };

    if (meta && meta.id !== undefined) {
      await updateMeta(meta.id, metaAtualizada); // Chama o update e atualiza as metas
    } else {
      await createMeta(metaAtualizada);
    }

    fecharModal(); // Fecha o modal após salvar
  };

  const handleExcluir = async (id?: number) => {
    if (id === undefined || id === null || id === 0 || loading) return;
    await deleteMeta(id);
    fecharModal();
  };

  useEffect(() => {
    if (meta) {
      setDados({
        categoria_id: meta.categoria_id?.toString() || "",
        mesAno: meta.mesAno || "",
        valor: valorParaTexto(meta.valor)
      });
    }
  }, [meta]);

  const opcoesCategoria = categorias
    .filter((categoria) => categoria.tipo === "D")
    .map((categoria) => {
      return { icone: categoria.icone, valor: categoria.id?.toString() || "", texto: categoria.nome };
    })
    .sort((a, b) => a.texto.localeCompare(b.texto));

  return (
    <Slider titulo="Adicionar Meta" subtitulo="Metas" onClose={() => fecharModal()} escurecerFundo={true}>
      <div className={styles.containerModal}>
        <div className={styles.wrapperModal}>
          <div className={styles.modalCampos}>
            <DropDown
              label="Categoria"
              opcoes={opcoesCategoria}
              valor={dados.categoria_id}
              onChange={(valor) => setDados({ ...dados, categoria_id: valor })}
              obrigatorio={true}
            />
            <DropDown
              label="Mês"
              opcoes={[...meses]}
              valor={dados.mesAno}
              onChange={(valor) => setDados({ ...dados, mesAno: valor })}
              obrigatorio={true}
            />
            <TextField
              type="currency"
              label="Valor da Meta"
              value={dados.valor}
              onChange={(valor) => setDados({ ...dados, valor: valor })}
              obrigatorio={true}
              enabled={!loading}
            />
          </div>
          <div className={styles.modalBotoes}>
            <Botao
              texto={!loading ? "Salvar" : ""}
              icone={!loading ? "check" : "progress_activity"}
              spin={loading}
              onClick={() => handleSalvar()}
              enabled={todosPreenchidos}
            />
            {meta && meta.id && !loading && (
              <Botao
                texto="Excluir"
                icone={"delete"}
                background="#D11A2A"
                color="white"
                onClick={() => setMostrarAvisoExclusao(true)}
                enabled={true}
              />
            )}
          </div>
        </div>
      </div>
      <Alerta
        titulo="Excluir Meta"
        descricao="Deseja realmente excluir essa meta?"
        onClose={() => setMostrarAvisoExclusao(false)}
        onConfirm={() => handleExcluir(meta?.id)}
        visivel={mostrarAvisoExclusao}
        opcoes={OpcoesAlerta.SIM_NAO}
      />
    </Slider>
  );
}

