import { createContext, useContext } from "react";
import { Usuario } from "../servicos/usuarios/typeUsuario";

type UsuarioContextType = {
  usuario: Usuario;
  setUsuario: React.Dispatch<React.SetStateAction<Usuario>>;
};

export const UsuarioContexto = createContext<UsuarioContextType | undefined>(undefined);

export const useUsuarioContexto = () => {
  const context = useContext(UsuarioContexto);
  if (!context) {
    throw new Error("useUsuarioContexto deve ser usado dentro de um UsuarioContexto.Provider");
  }
  return context;
};

