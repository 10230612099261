import React from "react";
import styles from "./SobreNos.module.css";

function SobreNos() {
  const isMobile = window.innerWidth < 768;

  return (
    <section className={styles.section}>
      <h2>Conheça o Finant</h2>
      <div className={styles.videoPlaceholder}>
        <iframe
          width="100%"
          height={isMobile ? "100%" : "500px"}
          src="https://www.youtube.com/embed/vWrCyXCNvz0?si=UrZr94fNGspYFDy2"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </section>
  );
}

export default SobreNos;

