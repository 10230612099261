import { useEffect, useState } from "react";
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { valorParaTexto } from "../../../utils/Utils";
import Informativo from "../../estruturais/informativo/Informativo";
import ResumoSuperior, { ResumoDash } from "../../estruturais/resumoSuperior/ResumoSuperior";
import styles from "./ResumoContas.module.css";
import MinhasTransacoes from "./MinhasTransacoes/MinhasTransacoes";
import TipoDespesas from "./TransacoesTipo/TipoDespesas";
import TipoReceitas from "./TransacoesTipo/TipoReceitas";
import MonthPicker from "../../../componentes/monthPicker/MonthPicker";

const isMobile = window.innerWidth < 768;

export default function ResumoContas() {
  const { loading, transacoes, fetchTransacoes } = useTransacoes();

  const [dataSelecionada, setDataSelecionada] = useState(new Date());

  const dataInicio = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth(), 1));
  const dataFim = new Date(Date.UTC(dataSelecionada.getFullYear(), dataSelecionada.getMonth() + 1, 0));

  const transacoesFiltradas = transacoes.filter((transacao) => {
    const dataTransacao = new Date(transacao.data);
    const dataTransacaoUTC = Date.UTC(dataTransacao.getFullYear(), dataTransacao.getMonth(), dataTransacao.getDate());
    const dataInicioUTC = Date.UTC(dataInicio.getFullYear(), dataInicio.getMonth(), dataInicio.getDate());
    const dataFimUTC = Date.UTC(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate());

    return dataTransacaoUTC >= dataInicioUTC && dataTransacaoUTC <= dataFimUTC;
  });

  const transacoesDespesas = transacoesFiltradas.filter((transacao) => transacao.tipo === "D" && !transacao.cartao_credito_id);
  const transacoesReceitas = transacoesFiltradas.filter((transacao) => transacao.tipo === "R" && !transacao.cartao_credito_id);

  //calcula o total de todas as transações, receitas - despesas
  const totalReceitas = transacoesReceitas.reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);
  const totalDespesas = transacoesDespesas.reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0) * -1;

  const totalTransacoes = totalReceitas + totalDespesas;
  const corTransacao = totalTransacoes > 0 ? "#198000" : "#df0101";

  useEffect(() => {
    if (!dataSelecionada) {
      return;
    }

    fetchTransacoes({ dataInicial: dataInicio, dataFinal: dataFim });
  }, [dataSelecionada]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cabecalho} style={{ justifyContent: isMobile ? "center" : "space-between" }}>
          {isMobile ? (
            <div />
          ) : (
            <Informativo>
              <span>Utilize o período ao lado para visualizar as movimentações de acordo com o mês desejado.</span>
            </Informativo>
          )}
          <MonthPicker dataSelecionada={dataSelecionada} setDataSelecionada={setDataSelecionada} />
        </div>
        <div className={styles.painel}>
          <ResumoSuperior titulo="Total mensal">
            <ResumoDash titulo="" cor={corTransacao}>
              <div style={{ fontSize: isMobile ? "16px" : "16px", fontWeight: "bold" }}>{valorParaTexto(totalTransacoes, true)}</div>
            </ResumoDash>
          </ResumoSuperior>
          <div className={styles.graficos}>
            <MinhasTransacoes tipo="D" loading={loading} transacoes={transacoesDespesas} />
            <TipoDespesas loading={loading} transacoes={transacoesFiltradas} />
            {/* <UltimasTransacoes loading={loading} transacoes={transacoesFiltradas} /> */}
          </div>
          <div className={styles.graficos}>
            <MinhasTransacoes tipo="R" loading={loading} transacoes={transacoesReceitas} />
            <TipoReceitas loading={loading} transacoes={transacoesFiltradas} />
          </div>
        </div>
      </div>
    </div>
  );
}

