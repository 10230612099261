import { useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./FormularioLogin.module.css";
import Botao from "../../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import { usePerfis } from "../../../servicos/perfis/usePerfil";
import useAvisos from "../../../hooks/useAviso";
import Loader from "../../loader/Loader";
import useFirebase from "../../../hooks/useFirebase";
import Google from "../../../images/google.svg";

export default function FormularioLogin() {
  const [dados, setDados] = useState({ email: "", senha: "" });

  const { login } = usePerfis(false);
  const { loading, loginWithCredentials, loginOrRegisterWithGoogle } = useFirebase();
  const { adicionarAviso } = useAvisos();

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (dados.email === "") {
      adicionarAviso("Informe o e-mail", "alerta");
      return;
    }

    if (dados.senha === "") {
      adicionarAviso("Informe a senha", "alerta");
      return;
    }

    const user = await loginWithCredentials(dados.email, dados.senha);

    if (user) {
      login(user.email ?? dados.email, user.uid, false);
    }
  };

  const handleLoginGoogle = async () => {
    const user = await loginOrRegisterWithGoogle();

    if (user) {
      login(user.email ?? "", user.uid, false);
    }
  };

  const todosPreenchidos = !!(dados.email && dados.senha);

  return (
    <div className={styles.formulario}>
      <TextField label="E-mail" value={dados.email} onChange={(email) => setDados({ ...dados, email: email })} type="text" enabled={true} />
      <TextField
        label="Senha"
        value={dados.senha}
        onChange={(senha) => setDados({ ...dados, senha: senha })}
        type="password"
        enabled={true}
      />
      <div className={styles.opcoes}>
        <div className={styles.opcao} onClick={() => navigate("/recuperar-senha")}>
          <span>Esqueci minha senha</span>
        </div>
        <div className={styles.opcao} onClick={() => navigate("/criar-conta")}>
          <span>Criar conta</span>
        </div>
      </div>
      <Botao
        texto={!loading ? "Entrar" : ""}
        icone={!loading ? "check" : "progress_activity"}
        onClick={() => handleLogin()}
        background="#ffd862"
        color="#333333"
        enabled={todosPreenchidos}
        spin={loading}
      />
      <div className={styles.provedores}>
        <span>ou</span>
        <div className={styles.provedor} onClick={() => handleLoginGoogle()}>
          <img src={Google} alt="Google" />
          <span>Entrar com o Google</span>
        </div>
      </div>
    </div>
  );
}

